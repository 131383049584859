import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";

const host = `${window.location.protocol}//${window.location.host}`;

const getClientId = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "CloudProduction": {
      return "00e37b14-55b5-467a-9333-a1e66182d2ab";
    }
    default: {
      return "dac12150-b0e3-4907-a1f7-27c921c6feff";
    }
  }
};

const getAuthority = () => {
  const directoryId =
    process.env.REACT_APP_STAGE === "CloudProduction"
      ? "f2bbed8e-9ee7-45f8-8981-2e4948c448b4"
      : "1a1663ce-0c39-4e79-84ef-55c15789bf33";

  return `https://login.microsoftonline.com/${directoryId}`;
};

const getScopes = () => {
  const accessId =
    process.env.REACT_APP_STAGE === "CloudProduction"
      ? "82abf776-d1dc-404a-97c4-849ad41b8f18"
      : "33ebf1e7-c3ab-4966-b249-fd3eb7d8ff3f";

  return `api://${accessId}/access_as_user`;
};

const config: Configuration = {
  auth: {
    authority: getAuthority(), // "https://login.microsoftonline.com/1a1663ce-0c39-4e79-84ef-55c15789bf33",
    clientId: getClientId(), //'dac12150-b0e3-4907-a1f7-27c921c6feff',
    navigateToLoginRequestUrl: true,
    redirectUri: host,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const authProvider = new PublicClientApplication(config);

export const authRequest: RedirectRequest = {
  scopes: [getScopes()],
};

export const acquireAccessToken = async (
  msalInstance: PublicClientApplication,
) => {
  const activeAccount = msalInstance.getActiveAccount(); // returns a non-null value only if we have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  const request = {
    scopes: [getScopes()],
    account: activeAccount || accounts[0],
  };

  if (!activeAccount && accounts.length === 0) {
    authProvider.acquireTokenRedirect(request);
  }

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (error) {
    authProvider.acquireTokenRedirect(request);
  }
};
