import { Paginator, PaginatorProps } from "../paginatior";
import { TitleBar, TitleBarProps } from "../titleBar";
import "./ItemsListDetail.scss";
import { ReactElement } from "react";

export const ItemsListDetail = (props: ItemsListDetailProps) => {
  const textSearch = (textToSearch: string) => {
    if (!props.title.textSearch) return;
    props.title.textSearch(textToSearch);
  };

  const filterButtonClick = () => {
    if (!props.title.filterButtonClick) return;
    props.title.filterButtonClick();
  };

  return (
    <div className="row items-list-detail">
      <TitleBar
        title={props.title.title}
        textSearch={textSearch}
        textSearchToolTip={props.title.textSearchToolTip}
        canFilterButton={props.title.canFilterButton}
        filterButtonToolTip={props.title.filterButtonToolTip}
        filterButtonClick={filterButtonClick}
      />
      <div className={`col-7 left-side ${props.leftSide.className}`}>
        <div className="row">
          <div className="col-12 item-list-container pb-3">
            {props.leftSide.component}
          </div>
          <div className="col-12 pager-container pt-2">
            <Paginator
              itemsPerPage={props.paginator.itemsPerPage}
              currentPage={props.paginator.currentPage}
              totalItems={props.paginator.totalItems}
              onNextPage={props.paginator.onNextPage}
              onPreviousPage={props.paginator.onPreviousPage}
              onItemsPerPageChange={props.paginator.onItemsPerPageChange}
            />
          </div>
        </div>
      </div>
      <div className={`col-5 right-side ${props.rightSide.className}`}>
        <div className="row">
          <div className="col-12 px-4 item-view-container">
            {props.rightSide.component}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ItemsListDetailProps {
  leftSide: ItemsListDetailComponent;
  rightSide: ItemsListDetailComponent;
  title: TitleBarProps;
  paginator: PaginatorProps;
}

interface ItemsListDetailComponent {
  className: string;
  component: ReactElement;
}
