import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Loading, TextInput, TitleBar } from "../../components";
import { SimDataDto, SimSubscriptionDataDto } from "../../models";
import {
  getSimData,
  getSimSubscriptions,
} from "../robots/robotView/RobotViewSim.service";
import { SimCardMainView } from "./SimCardMainView";
import { RobotViewSimSubscription } from "../robots/robotView/RobotViewSimSubscription";
import "../robots/robotView/RobotViewSim.scss";

export const SimCard = () => {
  const [isWorking, setIsWorking] = useState(false);
  const [iccid, setIccid] = useState("");
  const [isCardExist, setIsCardExist] = useState<boolean | undefined>(
    undefined,
  );
  const [simData, setSimData] = useState<SimDataDto | undefined>(undefined);
  const [subscriptions, setSubscriptions] = useState<SimSubscriptionDataDto[]>(
    [],
  );
  const { t } = useTranslation();

  const checkIccid = async () => {
    setIsWorking(true);

    try {
      const a = await getSimData(iccid);
      setIsCardExist(!!a ? true : false);
      if (!!a) {
        setSimData(a);
        setSubscriptions(await getSimSubscriptions(iccid));
      }
    } catch (error) {
      setIsCardExist(false);
    }
    setIsWorking(false);
  };

  return (
    <div className="row items-list-detail ">
      <TitleBar title={t("adminPanel.sim.title")} canFilterButton={false} />
      {isWorking && (
        <div className="pt-5">
          <Loading />
        </div>
      )}

      {!isWorking && (
        <div className="row ps-4">
          <div className="col-2 mb-3">
            <h5 className="pt-4 fw-bold">{t("adminPanel.sim.title")} </h5>{" "}
          </div>
          <div
            className="col-3 pe-0 me-4 mt-4"
            style={{ maxWidth: "max-content" }}
          >
            ICCID
          </div>
          <div className="col-3 ps-0 ms-0 mt-3" style={{ width: "250px" }}>
            <TextInput
              value={iccid}
              ret={(x) => {
                setIccid(x);
                setIsCardExist(undefined);
              }}
            />
          </div>
          <div className="col-1 pt-6 mt-4">
            <button
              className="btn btn-outline-cleanfix-no-border mt-6"
              style={{ maxWidth: "max-content" }}
              onClick={checkIccid}
            >
              {t("adminPanel.sim.check")}
            </button>
          </div>
          {isCardExist && simData !== undefined ? (
            <>
              <div className="row mt-5">
                <div
                  className="col d-flex
                        align-items-center
                        justify-content-center"
                >
                  {t("adminPanel.robots.sim.simData")}
                </div>
                <div
                  className="col d-flex
                        align-items-center
                        justify-content-center"
                >
                  {t("adminPanel.robots.sim.currentServices")}
                </div>
                <div
                  className="col d-flex
                        align-items-center
                        justify-content-center"
                >
                  {t("adminPanel.robots.sim.subscriptions")}
                </div>
                <div
                  className="col d-flex
                        align-items-center
                        justify-content-center"
                >
                  {t("adminPanel.robots.sim.availableSubscriptions")}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <SimCardMainView simData={simData} />
                </div>
                <div className="col">
                  <div className="accordion-container">
                    {simData.currentServices.map((service, index) => (
                      <div key={`${index}`}>
                        {!!index && (
                          <div style={{ borderTop: "1px solid silver" }}></div>
                        )}
                        <RobotViewSimSubscription service={service} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col">
                  <div className="accordion-container">
                    {simData.subscriptions.map((service, index) => (
                      <div key={`${index}`}>
                        {!!index && (
                          <div style={{ borderTop: "1px solid silver" }}></div>
                        )}
                        <RobotViewSimSubscription service={service} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div className="accordion-container">
                    {subscriptions.map((service, index) => (
                      <>
                        {!!index && (
                          <div style={{ borderTop: "1px solid silver" }}></div>
                        )}

                        <RobotViewSimSubscription
                          service={service}
                          key={service.productId}
                        />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : isCardExist === undefined ? null : (
            <div>{t("adminPanel.sim.noSimCard")}</div>
          )}
        </div>
      )}
    </div>
  );
};
