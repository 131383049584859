import {
  SimDataDto,
  SimRegisterPostDto,
  SimSubscriptionDataDto,
} from "../../../models";
import { axiosGet, axiosPost, axiosPut } from "../../../services";

const simUrl = "admin/v2/sim";

export const getSimData = async (iccid: string) => {
  return (await axiosGet(`${simUrl}/${iccid}`)).data as SimDataDto;
};

export const getSimSubscriptions = async (iccid: string) => {
  return (await axiosGet(`${simUrl}/${iccid}/available-subscriptions`))
    .data as SimSubscriptionDataDto[];
};

export const putSimAlias = async (iccid: string, alias: string) => {
  await axiosPut(`${simUrl}/${iccid}/alias?SimAlias=${alias}`, null);
};

export const putSimRemove = async (robotId: string) => {
  await axiosPut(`${simUrl}/${robotId}/remove-sim`, null);
};

export const putSimAssign = async (robotId: string, iccid: string) => {
  await axiosPut(`${simUrl}/${robotId}/assign-sim?iccid=${iccid}`, null);
};

export const putSimSubscription = async (
  iccid: string,
  productId: number,
  AutorenewSubscription: boolean,
) => {
  await axiosPut(
    `${simUrl}/${iccid}?SubscriptionProductId=${productId}&AutorenewSubscription=${AutorenewSubscription}`,
    null,
  );
};

export const postSim = async (data: SimRegisterPostDto) => {
  await axiosPost(simUrl, data);
};
