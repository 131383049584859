export const SubTitle = ({ text, squaredText }: SubTitleProps) => (
  <div className="d-flex  col-12 subtitle-in-tab mb-3 mt-4">
    <span className={`${squaredText ? "col-6" : "col-12"}`}>{text}</span>
    {squaredText && (
      <span className="text-center col-6 rectangle">{squaredText}</span>
    )}
  </div>
);

interface SubTitleProps {
  text: string;
  squaredText?: string;
}
