import { useTranslation } from "react-i18next";
import { DropDownMultiselect, ModalWindow } from "../../components";
import {
  RobotAdminFilterCriteria,
  RobotAdminFilteringDataDto,
  RobotLicense,
  RobotType,
} from "../../models";
import { useEffect, useState } from "react";

export const RobotsFilterModal = (props: RobotsFilterModalProps) => {
  const [selected, setSelected] = useState<RobotAdminFilterCriteria>({
    ...props.filter,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setSelected({ ...props.filter });
  }, [props.filter]);

  return (
    <ModalWindow
      size={"sm"}
      onClose={props.onClose}
      title={t("adminPanel.robots.filter.title")}
      footer={
        <button
          className="btn btn-outline-cleanfix"
          onClick={() => props.onApply(selected)}
        >
          {t("adminPanel.apply")}
        </button>
      }
    >
      <div className="row">
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.robotNames")}
            selectItemText={`Select ${t("adminPanel.robots.robotNames")}`}
            itemsToShow={props.filteringData.robotNames}
            itemsSelected={selected.robotNames}
            selectionChange={(x) => setSelected({ ...selected, robotNames: x })}
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.robotLocations")}
            selectItemText={`Select ${t("adminPanel.robots.robotLocations")}`}
            itemsToShow={props.filteringData.robotLocations}
            itemsSelected={selected.robotLocations}
            selectionChange={(x) =>
              setSelected({ ...selected, robotLocations: x })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.cleanfixSerialNumbers")}
            selectItemText={`Select ${t(
              "adminPanel.robots.cleanfixSerialNumbers",
            )}`}
            itemsToShow={props.filteringData.cleanfixSerialNumbers}
            itemsSelected={selected.cleanfixSerialNumbers}
            selectionChange={(x) =>
              setSelected({ ...selected, cleanfixSerialNumbers: x })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.antSerialNumbers")}
            selectItemText={`Select ${t("adminPanel.robots.antSerialNumbers")}`}
            itemsToShow={props.filteringData.antSerialNumbers}
            itemsSelected={selected.antSerialNumbers}
            selectionChange={(x) =>
              setSelected({ ...selected, antSerialNumbers: x })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.licenses")}
            selectItemText={`Select ${t("adminPanel.robots.licenses")}`}
            itemsToShow={props.filteringData.licenses}
            itemsSelected={selected.licenses}
            selectionChange={(x) =>
              setSelected({ ...selected, licenses: x as RobotLicense[] })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.robotTypes")}
            selectItemText={`Select ${t("adminPanel.robots.robotTypes")}`}
            itemsToShow={props.filteringData.robotTypes}
            itemsSelected={selected.robotTypes}
            selectionChange={(x) =>
              setSelected({ ...selected, robotTypes: x as RobotType[] })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.tenants")}
            selectItemText={`Select ${t("adminPanel.robots.tenants")}`}
            itemsToShow={props.filteringData.tenants}
            itemsSelected={selected.tenants}
            selectionChange={(x) => setSelected({ ...selected, tenants: x })}
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.antVersions")}
            selectItemText={`Select ${t("adminPanel.robots.antVersions")}`}
            itemsToShow={props.filteringData.antVersions}
            itemsSelected={selected.antVersions}
            selectionChange={(x) =>
              setSelected({ ...selected, antVersions: x })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <DropDownMultiselect
            label={t("adminPanel.robots.hmiVersions")}
            selectItemText={`Select ${t("adminPanel.robots.hmiVersions")}`}
            itemsToShow={props.filteringData.hmiVersions}
            itemsSelected={selected.hmiVersions}
            selectionChange={(x) =>
              setSelected({ ...selected, hmiVersions: x })
            }
          />
        </div>
      </div>
    </ModalWindow>
  );
};

interface RobotsFilterModalProps {
  filteringData: RobotAdminFilteringDataDto;
  filter: RobotAdminFilterCriteria;
  onClose: () => void;
  onApply: (selection: RobotAdminFilterCriteria) => void;
}
