import "./Unauthenticated.scss";
import cleanfixLogo from "../../assets/cleanfix-logo.png";
import robotImg from "../../assets/Robot.png";
import { useTranslation } from "react-i18next";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { authRequest } from "../../services";

export const Unauthenticated = () => {
  const { login } = useMsalAuthentication(InteractionType.Silent, authRequest);
  const { t } = useTranslation();

  return (
    <section className="container-fluid unauthenticated">
      <div className="row login-container">
        <div className="col left-side text-center">
          <p>{t("common.welcomeBack")}</p>

          <button
            type="button"
            className="btn btn-primary mt-4 px-5 pt-0 pb-1"
            onClick={() => login(InteractionType.Redirect, authRequest)}
          >
            {t("loginScreen.login")}
          </button>
        </div>

        <div className="col right-side text-center">
          <img src={robotImg} className="admin-panel-img" alt="Admin Panel" />
          <p className="mt-1 mb-2">{t("common.applicationName")}</p>
          <img src={cleanfixLogo} className="logo-brand" alt="Cleanfix" />
        </div>
      </div>
    </section>
  );
};
