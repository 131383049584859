import {
  Loading,
  TableGeneric,
  TooltipContainer,
  tableGenericColumn,
} from "../../../components";
import {
  OrderDto,
  RobotAdminFilterCriteria,
  RobotAdminListDto,
  RobotDefaultConfigurationDto,
  RobotStatisticsDto,
} from "../../../models";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";

import { Tooltip } from "@mui/material";
import { RobotsListViewBadgesCondainer } from "../../robots/RobotsListViewBadgesCondainer";
import { dateToHourDate } from "../../../tools";
import i18n from "../../../i18n/config";
import { formatWithApostrophes } from "../../../tools/number";

export const DashboardRobotsListView = (
  props: DashboardRobotsListViewProps,
) => {
  return (
    <>
      {props.isWorking && (
        <div className="col-12 item-list-loading text-center">
          <Loading size={120} />
        </div>
      )}
      {!props.isWorking && (
        <div className="mt-2">
          <RobotsListViewBadgesCondainer
            filter={props.filter}
            onBadgeChange={props.onBadgeChange}
          />
        </div>
      )}
      {!props.isWorking && (
        <div className="row">
          <TableGeneric
            columns={columnsDefinition}
            items={props.items.map((robot) => {
              const robotStatistics = props.itemsWithStatistic.find(
                (e) => robot.id === e.id,
              );
              return robotStatistics !== undefined
                ? {
                    id: robot.id,
                    cleanfixSerialNumber: robot.cleanfixSerialNumber,
                    name: robot.name,
                    totalAreaCleaned: robotStatistics.totalAreaCleaned
                      .toFixed(0)
                      .toString(),
                    totalCleaningTime: (robotStatistics.totalCleaningTime / 60)
                      .toFixed(0)
                      .toString(),
                    totalMissions: robotStatistics.totalMissions.toString(),
                    totalFailedMissions:
                      robotStatistics.totalFailedMissions.toString(),
                    totalSuccessfulMissions:
                      robotStatistics.totalSuccessfulMissions.toString(),
                    periodicUpdateTime: robot.periodicUpdateTime,
                    cloudConnection: robot.cloudConnection,
                  }
                : {
                    id: robot.id,
                    cleanfixSerialNumber: robot.cleanfixSerialNumber,
                    name: robot.name,

                    totalAreaCleaned: "-",
                    totalCleaningTime: "-",
                    totalMissions: "-",
                    totalFailedMissions: "-",
                    totalSuccessfulMissions: "-",
                    periodicUpdateTime: robot.periodicUpdateTime,
                    cloudConnection: robot.cloudConnection,
                  };
            })}
            idProperty="id"
            headerClick={(header, itemsOrder) =>
              props.onDataOrderChange(itemsOrder)
            }
            itemClick={(item) => props.onCurrentIdChange(item.id)}
            currentId={props.currentId}
            itemsOrder={props.dataOrder}
          />
        </div>
      )}
    </>
  );
};

interface DashboardRobotsListViewProps {
  items: RobotAdminListDto[];
  itemsWithStatistic: RobotStatisticsDto[];
  dataOrder: OrderDto;
  filter: RobotAdminFilterCriteria;
  onDataOrderChange: (dataOrder: OrderDto) => void;
  onBadgeChange: (selection: RobotAdminFilterCriteria) => void;
  currentId: string | undefined;
  isWorking: boolean;
  onCurrentIdChange: (currentId: string | undefined) => void;
  onRobotAdded: () => void;
  onRefresh: () => void;
  robotDefaultConfiguration: RobotDefaultConfigurationDto | undefined;
}

const columnsDefinition: tableGenericColumn[] = [
  {
    canSort: false,
    itemClassName: "td-1-icon pe-0",
    cellTemplate: (item: RobotAdminListDto) => (
      <>
        {item.cloudConnection ? (
          <TooltipContainer text={dateToHourDate(item.periodicUpdateTime)}>
            <WifiRoundedIcon className="on-line me-2" />
          </TooltipContainer>
        ) : (
          <TooltipContainer
            text={
              item.periodicUpdateTime
                ? dateToHourDate(item.periodicUpdateTime)
                : i18n.t("adminPanel.robots.notConnected")
            }
          >
            <WifiOffRoundedIcon className="off-line me-2" />
          </TooltipContainer>
        )}
      </>
    ),
  },
  {
    // title: i18n.t("adminPanel.robots.list.cleanfixSerialNumber"),
    title: "Serial",
    property: "cleanfixSerialNumber",
    canSort: false,
    cellTemplate: (item) => (
      <Tooltip title="Serial Number" followCursor={true}>
        <span>{item.cleanfixSerialNumber}</span>
      </Tooltip>
    ),
  },
  {
    title: "m²",
    property: "totalAreaCleaned",
    canSort: false,
    cellTemplate: (item) => (
      <Tooltip title="Area Cleaned" followCursor={true}>
        <span>
          {isNaN(item.totalAreaCleaned)
            ? item.totalAreaCleaned
            : formatWithApostrophes(item.totalAreaCleaned)}
        </span>
      </Tooltip>
    ),
  },
  {
    title: "h",
    property: "totalCleaningTime",
    canSort: false,
    cellTemplate: (item) => (
      <Tooltip title="Cleaning Time" followCursor={true}>
        <span>
          {isNaN(item.totalCleaningTime)
            ? item.totalCleaningTime
            : formatWithApostrophes(item.totalCleaningTime)}
        </span>
      </Tooltip>
    ),
  },
  {
    title: "M",
    property: "totalMissions",
    canSort: false,
    cellTemplate: (item) => (
      <Tooltip title="Total Missions" followCursor={true}>
        <span>{item.totalMissions}</span>
      </Tooltip>
    ),
  },
  {
    title: "F",
    property: "totalFailedMissions",
    canSort: false,
    cellTemplate: (item) => (
      <Tooltip title="Failed Missions" followCursor={true}>
        <span>{item.totalFailedMissions}</span>
      </Tooltip>
    ),
  },
  {
    title: "S",
    property: "totalSuccessfulMissions",
    canSort: false,
    cellTemplate: (item) => (
      <Tooltip title="Successful Missions" followCursor={true}>
        <span>{item.totalSuccessfulMissions}</span>
      </Tooltip>
    ),
  },
];
