import { Switch } from "@mui/material";

export const SwitchInput = (props: SwitchInputProps) => {
  return (
    <Switch
      color="primary"
      checked={props.checked}
      onChange={() => props.onCheckedChange(!props.checked)}
    />
  );
};

interface SwitchInputProps {
  checked: boolean;
  onCheckedChange: (chk: boolean) => void;
}
