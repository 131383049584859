import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMissionStatisticsData } from "./Dashboard.service";
import { ResponsiveBar } from "@nivo/bar";
import { ErrorHelper, ToastHelper } from "../../helpers";
import { getFirstDayOf6MonthsAgo } from "../../tools";
import "./Dashboard.scss"; // Import the CSS file
import {
  ColorMappingMissionCount,
  KeysMissionsRate,
  MissionData,
  MissionRateData,
  colorMapping,
  colorMappingMissionCount,
  keysMissionsCount,
} from "./Types";
import { Loading } from "../../components";

export const DashboardMissionsView = ({
  refreshCounter,
  setMissionStatisticsCreatedAt,
  toggleInvalidMissions,
}: {
  refreshCounter: number;
  setMissionStatisticsCreatedAt: (date: Date) => void;
  toggleInvalidMissions: boolean;
}) => {
  const { t } = useTranslation();
  const [totalMissions, setTotalMissions] = useState<number>(0);
  const [keysMissionsRate, setKeysMissionsRate] = useState<string[]>([]);
  const [missionsCountData, setMissionsCountData] = useState<
    Record<string, string | number>[] | undefined
  >(undefined);
  const [missionsRateData, setMissionsRateData] = useState<
    Record<string, string | number>[]
  >([]);
  const [unselectedKeys, setUnselectedKeys] = useState<string[]>([]);
  const [hideLegend, setHideLegend] = useState(false);

  useEffect(() => {
    const getStatisticData = async (statisticsSinceDate: string) => {
      try {
        const result = await getMissionStatisticsData(
          statisticsSinceDate,
          refreshCounter <= 0,
          toggleInvalidMissions,
        );
        if (!!result) {
          setTotalMissions(result.totalLifetimeMissions);

          // build missionsCountData
          const missionsCounts: Record<string, string | number>[] = [];
          result.missionsPerStatusAndMonth.map((item) => {
            missionsCounts.push({});
            const idx = missionsCounts.length - 1;
            missionsCounts[idx].month = item.month;
            item.statusCountList.map((status) => {
              missionsCounts[idx][status.name] = status.count;
            });
          });
          setMissionsCountData(missionsCounts);

          // build missionsRatesData
          const missionsRates: Record<string, string | number>[] = [];
          result.missionSuccessRatePerMonth.map((item) => {
            missionsRates.push({ ...item });
          });

          // process result.missionSuccessRatePerMonth to normalize in percentage
          missionsRates.map((item) => {
            const total =
              Number(item.successful) +
              Number(item.failed) +
              Number(item.unkown);
            item.successful = Math.round(
              (Number(item.successful) / total) * 100,
            );
            item.failed = Math.round((Number(item.failed) / total) * 100);
            item.unkown = Math.round((Number(item.unkown) / total) * 100);
          });

          setMissionsRateData(missionsRates);

          // Filter keys to exclude any that do not contain meaningful data across all records (e.g. unknown)
          const keys = Object.keys(missionsRates[0]);
          keys.shift(); // remove month key
          const meaningfulDataKeys = keys.filter((key) =>
            missionsRates.some((rate) => rate[key] !== null && rate[key] !== 0),
          );
          setKeysMissionsRate(meaningfulDataKeys);

          setMissionStatisticsCreatedAt(result.statisticsCreatedAt);
        }
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    setMissionsCountData(undefined);

    getStatisticData(getFirstDayOf6MonthsAgo());
  }, [refreshCounter, setMissionStatisticsCreatedAt, toggleInvalidMissions]);

  const getFilteredMissionsCounts = (): Record<string, string | number>[] => {
    if (missionsCountData != undefined) {
      const filteredMissionsCounts = missionsCountData.map((item) => {
        const filteredItem = { ...item };

        if (unselectedKeys.length > 0) {
          unselectedKeys.map((key) => {
            delete filteredItem[key];
          });
        }
        return filteredItem;
      });
      return filteredMissionsCounts;
    }
    return [];
  };

  const handleLegendClick = (id: string) => {
    setUnselectedKeys((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  return (
    <div className="dashboard-container">
      <div className="row">
        <div className="col-12 mt-3 mb-0">
          <div>
            <span className="dashboard-component-title">
              {t("adminPanel.dashboard.missions.title")}
            </span>
            <p className="mt-2 mb-2">
              <span>{"Total Missions: "}</span>
              <span>{totalMissions}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="row text-left">
        <div className="col-6 dashboard-component-subtitle">
          Mission Statuses
        </div>
        <div className="col-6 dashboard-component-subtitle">
          Missions Success Rate
        </div>
      </div>
      {missionsCountData != undefined ? (
        <div className="row">
          <div
            className={
              hideLegend ? "col-6 bar-height" : "col-6 bar-height-mission"
            }
          >
            <ResponsiveBar
              margin={{ top: 10, right: 0, bottom: 40, left: 60 }}
              data={
                missionsCountData === undefined
                  ? []
                  : getFilteredMissionsCounts()
              }
              indexBy={"month"}
              keys={keysMissionsCount}
              colors={({ id }) => {
                const key = id as keyof ColorMappingMissionCount; // Ensures 'id' is a key of 'ColorMapping'
                const color = !unselectedKeys.includes(key)
                  ? colorMappingMissionCount[key]
                  : "gray";
                return color;
              }}
              padding={0.2}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
                truncateTickAt: 0,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legendPosition: "middle",
                legendOffset: 32,
                truncateTickAt: 0,
              }}
              labelTextColor="black"
              labelSkipWidth={16}
              labelSkipHeight={16}
              legends={[]}
              tooltip={({ indexValue }) => {
                const missionData = missionsCountData.find(
                  (d) => (d as MissionData).month === indexValue,
                ) as MissionData;
                return (
                  <div className="slice-tooltip">
                    {keysMissionsCount.map((key) => (
                      <div key={key} className="tooltip-row-flex">
                        <div
                          className="tooltip-color-box"
                          style={{
                            backgroundColor:
                              colorMappingMissionCount[
                                key as keyof ColorMappingMissionCount
                              ] || "#000000",
                          }}
                        ></div>
                        {key}: {missionData[key]}
                      </div>
                    ))}
                  </div>
                );
              }}
              onClick={() => {
                setHideLegend(!hideLegend);
              }}
            />
            {!hideLegend && (
              <div className="legend-container-missions">
                {keysMissionsCount.map((key) => (
                  <div
                    key={key}
                    onClick={() => handleLegendClick(key)}
                    className={`legend-item ${
                      unselectedKeys.includes(key)
                        ? "legend-item--inactive"
                        : ""
                    }`}
                  >
                    <div
                      className="legend-color-box"
                      style={{
                        backgroundColor:
                          colorMappingMissionCount[
                            key as keyof ColorMappingMissionCount
                          ] || "#000000",
                      }}
                    ></div>
                    <span>{key}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-6 bar-height">
            <ResponsiveBar
              margin={{ top: 10, right: 0, bottom: 40, left: 40 }}
              data={missionsRateData}
              indexBy={"month"}
              colors={({ id }) => {
                const key = id as keyof KeysMissionsRate;
                return colorMapping[key];
              }}
              keys={keysMissionsRate}
              padding={0.2}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legendPosition: "middle",
                legendOffset: 32,
                truncateTickAt: 0,
              }}
              labelTextColor="black"
              labelSkipWidth={16}
              labelSkipHeight={16}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickValues: 10, // Generate ticks every 10%
                tickRotation: 0,
                format: (value) => `${value}%`,
              }}
              maxValue={100}
              minValue={0}
              valueFormat={(value) => `${value}%`}
              tooltip={({ indexValue }) => {
                const missionRateData = missionsRateData.find(
                  (d) => (d as MissionRateData).month === indexValue,
                ) as MissionRateData;
                return (
                  <div className="slice-tooltip">
                    {keysMissionsRate.map((key) => (
                      <div key={key} className="tooltip-row-flex">
                        <div
                          className="tooltip-color-box"
                          style={{
                            backgroundColor:
                              colorMapping[key as keyof typeof colorMapping] ||
                              "#000000",
                          }}
                        ></div>
                        {key.charAt(0).toUpperCase() + key.slice(1)}:{" "}
                        {missionRateData[key]}%
                      </div>
                    ))}
                  </div>
                );
              }}
            />
          </div>
        </div>
      ) : (
        <div className="bar-height">
          <Loading size={120} />
        </div>
      )}
    </div>
  );
};
