export function formatWithApostrophes(input: number): string {
  // Convert the floored number to a string
  const inputStr = Math.floor(input).toString();

  // Use a regular expression to insert the apostrophe
  // The regex looks for groups of 3 digits from the end of the string
  const formattedStr = inputStr.replace(/\B(?=(\d{3})+(?!\d))/g, "'");

  return formattedStr;
}
