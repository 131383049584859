import { useState } from "react";

export const usePagination = () => {
  const [pageValues, setPageValues] = useState({
    ...pageValuesDefault,
  });

  const itemsPerPageChange = (num: number) => {
    setPageValues({
      ...pageValues,
      currentPage: 1,
      itemsPerPage: num,
    });
  };

  const totalItemsChange = (num: number) => {
    if (pageValues.totalItems === num) return;

    setPageValues({
      ...pageValues,
      totalItems: num ?? 0,
    });
  };

  const goNext = () => {
    setPageValues({
      ...pageValues,
      currentPage: pageValues.currentPage + 1,
    });
  };

  const goPrevious = () => {
    setPageValues({
      ...pageValues,
      currentPage: pageValues.currentPage - 1,
    });
  };

  const setCurrentPage = (pageNo: number) => {
    setPageValues({
      ...pageValues,
      currentPage: pageNo,
    });
  };

  return {
    pageValues,
    itemsPerPageChange,
    totalItemsChange,
    goNext,
    goPrevious,
    setCurrentPage,
  };
};

interface PageValuesState {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
}

const pageValuesDefault: PageValuesState = {
  itemsPerPage: 25,
  currentPage: 1,
  totalItems: 0,
};
