/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";
import { RobotAdminDto } from "../../../models";
import { UiConfig, uiConfigDefault } from "./RobotViewUiConfig";

export const RobotViewContext = createContext<RobotViewContextData>({
  robotAdmin: undefined,
  setRobotAdmin: () => {},
  refresh: async () => {},
  currentTab: 0,
  setCurrentTab: () => {},
  uiConfig: { ...uiConfigDefault },
  setUiConfig: () => {},
  refreshList: (id?: string) => {},
});

interface RobotViewContextData {
  robotAdmin: RobotAdminDto | undefined;
  setRobotAdmin(ra: RobotAdminDto | undefined): void;
  refresh: () => Promise<void>;
  currentTab: number;
  setCurrentTab: (newTab: number) => void;
  uiConfig: UiConfig;
  setUiConfig: (value: UiConfig) => void;
  refreshList: (id?: string) => void;
}
