export const dateToHourDate = (parameter: Date | string | null | undefined) => {
  if (!parameter) {
    return "";
  }

  const date = new Date(parameter);

  return (
    `${date.getHours().toString().padStart(2, "0")}:` +
    `${date.getMinutes().toString().padStart(2, "0")} ` +
    `${date.getDate().toString().padStart(2, "0")}.` +
    `${(date.getMonth() + 1).toString().padStart(2, "0")}.` +
    `${date.getFullYear().toString().padStart(4, "0")}`
  );
};

/**
 * Returns the ISO date string of the first day of the month, 12 months ago from the current date.
 * @returns {string} An ISO date string in the format YYYY-MM-DD.
 */
export function getFirstDayOf6MonthsAgo(): string {
  const currentDate = new Date();

  // Subtract 6 months from the current date
  currentDate.setMonth(currentDate.getMonth() - 6);

  // Set the date to the first of the month
  currentDate.setDate(1);

  // Extract the year and the month, and format the month to ensure it's always two digits
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // getMonth() returns a zero-based index

  // Construct the ISO date string
  const isoDate = `${year}-${String(month).padStart(2, "0")}-01`;

  return isoDate;
}
