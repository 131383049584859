import Icon from "@mui/material/Icon";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";

type RobotControllerItemStatusProp = {
  label: string;
  healthy?: boolean;
  value?: string | null;
  state?: string;
  errorEnum?: string;
  remark?: string;
};

export const RobotCucItemStatus = ({
  label,
  healthy,
  value,
  state,
  errorEnum,
  remark,
}: RobotControllerItemStatusProp): JSX.Element => {
  const { t } = useTranslation();
  const getStateAndError = (state?: string, errorEnum?: string): string => {
    if (state && errorEnum && errorEnum !== "NoError") {
      return `${state} (${t(`check.cuc.enums.${errorEnum}`)})`;
    }
    if (state) {
      return state;
    }
    return "";
  };

  return (
    <>
      <div className="col-3 ps-5 mb-3">
        {healthy !== undefined ? (
          <Icon>
            {healthy ? (
              <CheckCircle color="success" style={{ verticalAlign: "top" }} />
            ) : (
              <Error color="error" style={{ verticalAlign: "top" }} />
            )}
          </Icon>
        ) : (
          <></>
        )}
      </div>
      <>{""}</>
      <div className="d-flex flex-column col-9">
        <div className="text-end">
          <div className="d-inline" style={{ verticalAlign: "super" }}>
            <span>{label ?? ""}</span>
            {state !== undefined && (
              <>
                <span> - </span>
                <span style={{ color: "#3b85bb", fontStyle: "italic" }}>
                  {getStateAndError(state, errorEnum)}
                </span>
              </>
            )}
            {value !== undefined && <span> {` - ${value ?? "Unknown"}`}</span>}
            {remark !== undefined && (
              <span style={{ color: "#a3a3a3" }}> {` [${remark}]`}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
