import { dateToHourDate } from "../../../tools";
import { RobotViewContext } from "./RobotViewContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BatteryFullOutlinedIcon from "@mui/icons-material/BatteryFullOutlined";
import SpeakerPhoneOutlinedIcon from "@mui/icons-material/SpeakerPhoneOutlined";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { TooltipButton } from "../../../components";
import { NoSim, SimCard } from "@mui/icons-material";

export const RobotInformation = () => {
  const { robotAdmin } = useContext(RobotViewContext);

  const { t } = useTranslation();

  if (!robotAdmin) return <></>;

  return (
    <div className="row pt-4 robot-information">
      <div className="col-12">
        <h3 className=" title">{robotAdmin.settings.name}</h3>
      </div>
      <div className="col-12">
        <div className="d-flex">
          <div className="flex-fill">
            <span>{robotAdmin.cleanfixSerialNumber}</span>
          </div>
          <div className="d-flex">
            {robotAdmin.settings.remoteSupportEnabled &&
              (robotAdmin?.reverseSshPort ?? 0) > 0 && (
                <>
                  <span className="align-self-center">
                    {robotAdmin.reverseSshPort}
                  </span>
                  <SpeakerPhoneOutlinedIcon className="ms-1 me-4 align-self-center" />
                </>
              )}
            <span className="align-self-center">SIM</span>
            {robotAdmin.iccid !== null ? (
              <div className="me-3">
                <TooltipButton
                  tooltip={t("adminPanel.robots.actionBar.simCardConnected")}
                >
                  <SimCard />
                </TooltipButton>
              </div>
            ) : (
              <div className="me-3">
                <TooltipButton
                  tooltip={t("adminPanel.robots.actionBar.noSimCardConnected")}
                >
                  <NoSim className="off-line" />
                </TooltipButton>
              </div>
            )}

            <span className="align-self-center">
              {robotAdmin.settings.location}
            </span>
            <LocationOnIcon className="ms-1 me-4 align-self-center" />
            <span className=" align-self-center">
              {robotAdmin.batteryPower}%
            </span>
            <BatteryFullOutlinedIcon className="ms-1 align-self-center" />
          </div>
        </div>
      </div>
      <div className="col-12 pt-2">
        <div className="d-flex">
          <div className="text-center rectangle px-2 py-1 me-3">
            {robotAdmin.settings.robotType}
            {!robotAdmin.settings.robotType && (
              <>
                <span className="me-2">{t("adminPanel.robots.list.type")}</span>
                <WarningAmberIcon className="no-license-or-type" />
              </>
            )}
          </div>
          <div className="text-center rectangle px-2 py-1 me-3">
            {robotAdmin.settings.license}
            {!robotAdmin.settings.license && (
              <>
                <span className="me-2">
                  {t("adminPanel.robots.list.license")}
                </span>
                <WarningAmberIcon className="no-license-or-type" />
              </>
            )}
          </div>

          <div className="align-self-center">
            {robotAdmin.cloudConnection && (
              <>
                <WifiRoundedIcon className="on-line me-2" />
                {dateToHourDate(robotAdmin.periodicUpdateTime)}
              </>
            )}
            {!robotAdmin.cloudConnection && (
              <>
                <WifiOffRoundedIcon className="off-line me-2" />
                {robotAdmin.periodicUpdateTime
                  ? dateToHourDate(robotAdmin.periodicUpdateTime)
                  : t("adminPanel.robots.notConnected")}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
