import { useTranslation } from "react-i18next";
import { SubTitle, TextValue } from "./common";
import { useContext, useEffect, useState } from "react";
import { RobotViewContext } from "./RobotViewContext";
import { RobotCucItemStatus } from "./RobotCucItemStatus";
import {
  addRobotControlListener,
  useCloudApiHub,
} from "../../../hooks/useCloudApiHub";
import { startReceivingCucData } from "../robots.service";
import { RobotCucDto } from "../../../models";
import { RobotCucCleaningDeviceStatus } from "./RobotCucCleaningDeviceStatus";
import { RobotCucCleaningLiftDeviceStatus } from "./RobotCucCleaningLiftDeviceStatus";

export const RobotViewCuc = () => {
  const { t } = useTranslation();
  const { robotAdmin } = useContext(RobotViewContext);
  const [robotCuc, setRobotCuc] = useState<RobotCucDto | undefined>(
    robotAdmin?.robotCuc ?? undefined,
  );
  const [receivingCucData, setReceivingCucData] = useState(false);
  const [lastReceivedCucData, setLastReceivedCucData] = useState<
    Date | undefined
  >(undefined);

  const hubConnection = useCloudApiHub(
    robotAdmin?.cloudConnection ? robotAdmin?.id : undefined,
  );

  useEffect(() => {
    if (!hubConnection) return;
    addRobotControlListener(
      hubConnection,
      "ReceiveRobotControl",
      (robotControl) => {
        setRobotCuc(robotControl);
        setLastReceivedCucData(new Date());
      },
    );

    () => hubConnection.off("ReceiveRobotControl");
  }, [hubConnection]);

  useEffect(() => {
    if (robotAdmin?.id && robotAdmin.cloudConnection && !receivingCucData) {
      setReceivingCucData(true);
      startReceivingCucData(robotAdmin?.id).catch((error) => {
        console.log(error);
      });
    }
  }, [robotAdmin?.id, robotAdmin?.cloudConnection, receivingCucData]);

  // Check if we are receiving controller information
  useEffect(() => {
    const interval = setInterval(() => {
      if (robotAdmin && robotAdmin?.cloudConnection && lastReceivedCucData) {
        const diff = new Date().getTime() - lastReceivedCucData.getTime();
        if (diff > 6000) {
          startReceivingCucData(robotAdmin?.id).catch((error) => {
            console.log(error);
          });
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastReceivedCucData, robotAdmin]);

  if (!robotAdmin) return <></>;

  const getLastReceivedCucData = () => {
    return lastReceivedCucData
      ? `Live ${lastReceivedCucData?.toLocaleDateString() ?? ""} ${
          lastReceivedCucData?.toLocaleTimeString() ?? ""
        }`
      : `Offline ${
          robotCuc?.latestChange
            ? new Date(robotCuc.latestChange).toLocaleDateString()
            : ""
        } ${
          robotCuc?.latestChange
            ? new Date(robotCuc.latestChange).toLocaleTimeString()
            : ""
        } `;
  };
  return (
    <div className="row controller-tab">
      <SubTitle
        text={`${t("check.cuc.cleaningUnitController")}`}
        squaredText={getLastReceivedCucData()}
      />
      <TextValue
        text={t("check.cuc.firmwareVersion")}
        value={robotCuc?.firmwareVersion}
        className="align-self-center"
      ></TextValue>
      <TextValue
        text={t("check.cuc.serialNumber")}
        value={robotCuc?.serialNumber}
        className="align-self-center"
      ></TextValue>
      {/* SafetyState */}
      <SubTitle
        text={t("check.cuc.safetyState")}
        squaredText={t(
          `check.cuc.enums.${
            robotCuc?.safetyInformation?.safetyState ?? "NoAvailableData"
          }`,
        )?.toString()}
      />
      <RobotCucItemStatus
        label={t("check.cuc.24VPowerSupply")}
        healthy={!robotCuc?.safetyInformation?.power24v ?? false}
      />
      <RobotCucItemStatus
        label={t("check.cuc.recoveryError")}
        healthy={!robotCuc?.safetyInformation?.recoveryError ?? false}
      />
      <RobotCucItemStatus
        label={t("check.cuc.antSafety")}
        healthy={!robotCuc?.safetyInformation?.antError ?? false}
      />
      <RobotCucItemStatus
        label={t("check.cuc.frontShieldBumpers")}
        healthy={!robotCuc?.safetyInformation?.shieldBumpers ?? false}
      />
      <RobotCucItemStatus
        label={t("check.cuc.floorDropSensors")}
        healthy={!robotCuc?.safetyInformation?.floorSensors ?? false}
      />
      <RobotCucItemStatus
        label={t("check.cuc.emergencyStopButton")}
        healthy={!robotCuc?.safetyInformation?.emergencyStop ?? false}
      />
      <RobotCucItemStatus
        label={t("check.cuc.controlAreaNetworkTimeout")}
        healthy={!robotCuc?.safetyInformation?.canTimeout ?? false}
      />
      {/* CleaningDevices */}
      <SubTitle text={t("check.cuc.cleaningDevices")} />
      <RobotCucItemStatus
        label={t("check.cuc.manager")}
        healthy={robotCuc?.cleaningInformation?.manager?.healthy ?? false}
        state={t(
          `check.cuc.enums.${
            robotCuc?.cleaningInformation?.manager?.state ?? "NoAvailableData"
          }`,
        )?.toString()}
        errorEnum={robotCuc?.cleaningInformation?.manager?.error.toString()}
        remark={
          robotCuc?.cleaningInformation?.manager
            ? `${t("check.cuc.dryRun")} ${t(
                `check.cuc.${robotCuc?.cleaningInformation?.manager?.dryRun.toString()}`,
              )}`
            : ""
        }
      />
      <RobotCucCleaningDeviceStatus
        label={t("check.cuc.brushMotor")}
        item={robotCuc?.cleaningInformation?.brush ?? undefined}
      />
      <RobotCucCleaningDeviceStatus
        label={t("check.cuc.suctionMotor")}
        item={robotCuc?.cleaningInformation?.suction ?? undefined}
      />
      <RobotCucCleaningDeviceStatus
        label={t("check.cuc.waterPump")}
        item={robotCuc?.cleaningInformation?.waterPump ?? undefined}
      />
      <RobotCucCleaningLiftDeviceStatus
        label={t("check.cuc.brushLift")}
        item={robotCuc?.cleaningInformation?.brushLift ?? undefined}
      />
      <RobotCucCleaningLiftDeviceStatus
        label={t("check.cuc.suctionLift")}
        item={robotCuc?.cleaningInformation?.suctionLift ?? undefined}
      />
      <SubTitle
        text={t("check.cuc.batteryState")}
        squaredText={t(
          `check.cuc.enums.${
            robotCuc?.batteryStatus?.batteryState ?? "NoAvailableData"
          }`,
        )?.toString()}
      />
      <TextValue
        text={t("check.cuc.stateOfCharge")}
        value={`${robotCuc?.batteryStatus?.chargeState ?? ""} %`}
        className="align-self-center"
      ></TextValue>
    </div>
  );
};
