import { useTranslation } from "react-i18next";
import { putRobotAction } from "./RobotViewSystem.service";
import { useContext, useState } from "react";
import { RobotViewContext } from "./RobotViewContext";
import { TextValue, SubTitle } from "./common";
import { RobotAdminActions } from "../../../models";
import { ModalWindow } from "../../../components";
import { ErrorHelper, ToastHelper } from "../../../helpers";

export const RobotViewSystem = () => {
  const [confirmAction, setConfirmAction] = useState<
    RobotAdminActions | undefined
  >(undefined);

  const { t } = useTranslation();

  const { robotAdmin } = useContext(RobotViewContext);
  if (!robotAdmin) return <></>;

  return (
    <div className="row system-tab">
      <SubTitle text={t("adminPanel.robots.system.robotStatus.title")} />
      <TextValue
        text={t("adminPanel.robots.system.robotStatus.status")}
        value={robotAdmin.status}
      />

      <TextValue
        text={t("adminPanel.robots.system.robotStatus.errorMessage")}
        value={robotAdmin.errorStatus}
      />

      <SubTitle text={t("adminPanel.robots.system.systemInformation.title")} />
      <TextValue
        text={t("adminPanel.robots.system.systemInformation.tenant")}
        value={robotAdmin.tenant}
      />
      <TextValue
        text={t(
          "adminPanel.robots.system.systemInformation.cleanfixSerialNumber",
        )}
        value={robotAdmin.cleanfixSerialNumber}
      />

      <TextValue
        text={t(
          "adminPanel.robots.system.systemInformation.routerSerialNumber",
        )}
        value={robotAdmin.routerSerialNumber}
      />

      <TextValue
        text={t("adminPanel.robots.system.systemInformation.antSerialNumber")}
        value={robotAdmin.antSerialNumber}
      />

      <SubTitle
        text={t("adminPanel.robots.system.firmwareInformation.title")}
      />
      <TextValue
        text={t("adminPanel.robots.system.firmwareInformation.hmi")}
        value={
          robotAdmin.cachedFirmwareInfo?.installedFirmwareInfo?.robotHmi
            ?.version
        }
      />
      <TextValue
        text={t("adminPanel.robots.system.firmwareInformation.services")}
        value={
          robotAdmin.cachedFirmwareInfo?.installedFirmwareInfo?.robotServices
            ?.version
        }
      />

      <TextValue
        text={t("adminPanel.robots.system.firmwareInformation.ant")}
        value={
          robotAdmin.cachedFirmwareInfo?.installedFirmwareInfo?.ant?.version
        }
      />
      <TextValue
        text={t("adminPanel.robots.system.firmwareInformation.updateService")}
        value={
          robotAdmin.cachedFirmwareInfo?.installedFirmwareInfo?.updateService
            ?.version
        }
      />
      <div className="col-12 text-end mb-3">
        <button
          className="btn btn-outline-cleanfix-no-border"
          onClick={() => setConfirmAction("Update")}
        >
          {t("adminPanel.robots.system.systemAction.update")}
        </button>
      </div>
      <SubTitle text={t("adminPanel.robots.system.systemAction.title")} />

      <div className="row mt-1">
        <div className="col"></div>
        <button
          className="btn btn-outline-cleanfix-no-border col-4 me-3"
          onClick={() => setConfirmAction("Reboot")}
        >
          {t("adminPanel.robots.system.systemAction.reboot")}
        </button>
        <button
          style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
          className="btn btn-outline-cleanfix-no-border col-4 "
          onClick={() => setConfirmAction("ResetServices")}
        >
          {t("adminPanel.robots.system.systemAction.resetServices")}
        </button>
      </div>
      <div className="row mt-3">
        <div className="col"></div>
        <button
          style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
          className="btn btn-outline-cleanfix-no-border col-4 me-3"
          onClick={() => setConfirmAction("DeleteDatabase")}
        >
          {t("adminPanel.robots.system.systemAction.deleteDatabase")}
        </button>
        <button
          style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
          className="btn btn-outline-cleanfix-no-border col-4"
          onClick={() => setConfirmAction("ImportZones")}
        >
          {t("adminPanel.robots.system.systemAction.importZones")}
        </button>
      </div>
      {confirmAction && (
        <ModalWindow
          size={"xs"}
          onClose={() => setConfirmAction(undefined)}
          title={t(
            `adminPanel.robots.system.confirmSystemActionTitle.${confirmAction}`,
          )}
          footer={
            <>
              <button
                className="btn btn-cleanfix text-uppercase me-2"
                onClick={() => {
                  try {
                    putRobotAction(robotAdmin.id, confirmAction);
                    ToastHelper.success(t("adminPanel.notifications.success"));
                  } catch (error) {
                    ToastHelper.errors(ErrorHelper.process(error));
                  }
                  setConfirmAction(undefined);
                }}
              >
                {t("common.yes")}
              </button>

              <button
                className="btn btn-cleanfix text-uppercase "
                onClick={() => setConfirmAction(undefined)}
              >
                {t("common.no")}
              </button>
            </>
          }
        >
          <>
            {t(
              `adminPanel.robots.system.confirmSystemActionMessage.${confirmAction}`,
            )}
          </>
        </ModalWindow>
      )}
    </div>
  );
};
