import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SimSubscriptionDataDto } from "../../../models";

export const RobotViewSimSubscription = ({
  service,
}: robotViewSimServiceProps) => {
  return (
    <>
      <Accordion
        disableGutters
        elevation={0}
        sx={{
          "&:before": {
            display: "none",
          },
          marginLeft: "12px",
          marginRight: "12px",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {service.shortName}
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <table className="run-header-table w-100">
            <tbody>
              <tr>
                <td className="td-8 pe-2">{"Type"}</td>
                <td className="td-4 pe-2">{service.type}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Status"}</td>
                <td className="td-4 pe-2">{service.status}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Data"}</td>
                <td className="td-4 pe-2">{service.data}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Short name"}</td>
                <td className="td-4 pe-2">{service.shortName}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Original charge"}</td>
                <td className="td-4 pe-2">{service.originalCharge}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Days left"}</td>
                <td className="td-4 pe-2">{service.daysLeft}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Product Id"}</td>
                <td className="td-4 pe-2">{service.productId}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Charge"}</td>
                <td className="td-4 pe-2">{service.charge}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Start date"}</td>
                <td className="td-4 pe-2">{service.startDate}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"End date"}</td>
                <td className="td-4 pe-2">{service.endDate}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Min contract duration"}</td>
                <td className="td-4 pe-2">{service.minContractDuration}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Min contract duration unit"}</td>
                <td className="td-4 pe-2">{service.minContractDurationUnit}</td>
              </tr>
              <tr>
                <td className="td-8 pe-2">{"Contract end date"}</td>
                <td className="td-4 pe-2">{service.contractEndDate}</td>
              </tr>
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

interface robotViewSimServiceProps {
  service: SimSubscriptionDataDto;
}
