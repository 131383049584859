import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SimDataDto } from "../../../models";

export const RobotViewSimData = ({ simData }: robotViewSimMainDataProps) => {
  return (
    <Accordion
      disableGutters
      elevation={0}
      className="accordion-container"
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <table className="run-header-table w-100">
          <tbody>
            <tr>
              <td className="td-8 pe-2">{"Iccid"}</td>
              <td>{simData.iccid}</td>
            </tr>
            <tr>
              <td className="td-8 pe-2">{"Sim alias"}</td>
              <td>{simData.simAlias}</td>
            </tr>
            <tr>
              <td className="td-8 pe-2">{"Provider"}</td>
              <td>{simData.provider}</td>
            </tr>
            <tr>
              <td className="td-8 pe-2">{"Subscription status"}</td>
              <td>{simData.subscriptionStatus}</td>
            </tr>
          </tbody>
        </table>
      </AccordionSummary>
      <AccordionDetails className="accordion-detail">
        <table className="run-header-table w-100">
          <tbody>
            <tr>
              <td className="td-8 pe-2">{"Imsi"}</td>
              <td>{simData.imsi}</td>
            </tr>
            <tr>
              <td className="td-8 pe-2">{"Msisdn"}</td>
              <td>{simData.msisdn}</td>
            </tr>
            <tr>
              <td className="td-8 pe-2">{"Apn"}</td>
              <td>{simData.apn}</td>
            </tr>
            <tr>
              <td className="td-8 pe-2">{"Contract number"}</td>
              <td>{simData.contractNumber}</td>
            </tr>
            <tr>
              <td className="td-8 pe-2">{"simType"}</td>
              <td>{simData.simType}</td>
            </tr>
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  );
};

interface robotViewSimMainDataProps {
  simData: SimDataDto;
}
