import "./Main.scss";
import { LeftSidePanel } from "./leftSideBar/LeftSidePanel";
import { RightSide } from "./rightSideBar/RightSide";

export const Main = () => {
  return (
    <section className="container-fluid main-container pe-0">
      <div className="d-flex">
        <LeftSidePanel />
        <RightSide />
      </div>
    </section>
  );
};
