/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from "react";
import { Loading, ModalWindow, TooltipContainer } from "../../../components";
import { useTranslation } from "react-i18next";
import { getRobotAccessSettings } from "./RobotAccessSettingsModal.service";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { RobotConfigAdminDto } from "../../../models";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const RobotAccessSettingsModal = (
  props: RobotAccessSettingsModalProps,
) => {
  const [isWorking, setIsWorking] = useState(true);
  const [data, setData] = useState<RobotConfigAdminDto | undefined>(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    const getData = async (id: string) => {
      try {
        setData(await getRobotAccessSettings(id));
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
        props.onClose();
      }

      setIsWorking(false);
    };

    getData(props.robotId);
  }, [props]);

  const copyData = () => {
    if (!data) return;

    const strData =
      "{\r\n\t" +
      `"RobotId": "${data.robotId}",\r\n\t` +
      `"SerialNumber": "${data.serialNumber}",\r\n\t` +
      `"ConnectionString": "${data.connectionString}",\r\n\t` +
      `"SasKey": "${data.sasKey}",\r\n\t` +
      `"ReverseSSHPort": "${data.reverseSSHPort ?? "null"}"\r\n` +
      "}";

    navigator.clipboard.writeText(strData);
  };

  return (
    <ModalWindow
      size={"md"}
      onClose={props.onClose}
      title={t("adminPanel.robots.accessSettingsModal.title")}
      footer={
        <>
          {!isWorking && (
            <button
              className="btn btn-cleanfix text-uppercase "
              onClick={props.onClose}
            >
              {t("common.ok")}
            </button>
          )}
        </>
      }
    >
      <>
        {isWorking && (
          <div className="row py-5">
            <Loading size={100} />
          </div>
        )}

        {!isWorking && data && (
          <div className="row robot-access-settings-modal">
            <div className="col-12 px-5 mb-3">
              <div className="config-container px-4 py-4">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td className="py-0">{"{"}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="ps-5 py-0">"RobotId":</td>
                      <td className="py-0 td-warp">"{data.robotId}",</td>
                    </tr>
                    <tr>
                      <td className="ps-5 py-0">"SerialNumber":</td>
                      <td className="py-0 td-warp">"{data.serialNumber}",</td>
                    </tr>
                    <tr>
                      <td className="ps-5 py-0">"ConnectionString":</td>
                      <td className="py-0 td-warp">
                        "{data.connectionString}",
                      </td>
                    </tr>
                    <tr>
                      <td className="ps-5 py-0">"SasKey":</td>
                      <td className="py-0 td-warp">"{data.sasKey}",</td>
                    </tr>
                    <tr>
                      <td className="ps-5 py-0">"ReverseSSHPort":</td>
                      <td className="py-0 td-warp">
                        {data.reverseSSHPort
                          ? `"${data.reverseSSHPort}"`
                          : `"${null}"`}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-0">{"}"}</td>
                      <td className="py-0"></td>
                    </tr>
                  </tbody>
                </table>

                <div className="text-end">
                  <TooltipContainer
                    text={t("adminPanel.robots.accessSettingsModal.copyData")}
                  >
                    <ContentCopyIcon
                      className="icon-button color-cleanfix"
                      onClick={copyData}
                    />
                  </TooltipContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </ModalWindow>
  );
};

interface RobotAccessSettingsModalProps {
  robotId: string;
  onClose: () => void;
}
