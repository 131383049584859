import "./ModalWindow.scss";
import { Dialog } from "@mui/material";
import { ReactElement } from "react";

export const ModalWindow = (props: ModalProps) => {
  return (
    <Dialog
      className="modal-window"
      open={true}
      maxWidth={props.size}
      onClose={props.onClose}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">{props.title}</h3>
        </div>
        <div className="modal-body">{props.children}</div>
        {props.footer && <div className="modal-footer p-2">{props.footer}</div>}
      </div>
    </Dialog>
  );
};

export interface ModalProps {
  size: "xs" | "sm" | "md" | "lg" | "xl";
  onClose: () => void;
  title: string;
  children?: ReactElement | never[];
  footer?: ReactElement | never[];
}
