import { ReactElement } from "react";
import { IconButton } from "@mui/material";
import { TooltipContainer, toolTipPlacement } from "../tooltipContainer";

export const TooltipButton = (props: tooltipButtonProps) => {
  return (
    <TooltipContainer
      text={
        (props.disabled
          ? props.tooltipDisable ?? props.tooltip
          : props.tooltip) ?? ""
      }
      content={props.tooltipContent}
      className={props.className}
      placement={props.tooltipPlacement}
      enterDelay={1000}
      leaveDelay={200}
    >
      <>
        <IconButton
          color="inherit"
          aria-label="filter"
          onClick={props.onClick}
          size={props.size}
          edge={props.edge}
          className={props.className}
          disabled={props.disabled}
        >
          {props.children}
        </IconButton>
        <span></span>
      </>
    </TooltipContainer>
  );
};

interface tooltipButtonProps {
  tooltip?: string | null | undefined;
  tooltipDisable?: string;
  tooltipContent?: ReactElement;
  tooltipPlacement?: toolTipPlacement;

  onClick?: () => void;
  children: ReactElement | never[] | null;
  size?: "medium" | "small" | "large" | undefined;
  edge?: "start" | "end" | false | undefined;
  className?: string;
  disabled?: boolean;
}

TooltipButton.defaultProps = {
  disabled: false,
};
