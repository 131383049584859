import {
  Loading,
  TableGeneric,
  TooltipButton,
  TooltipContainer,
  tableGenericColumn,
} from "../../components";
import i18n from "../../i18n/config";
import {
  OrderDto,
  RobotAdminFilterCriteria,
  RobotAdminListDto,
  RobotDefaultConfigurationDto,
} from "../../models";
import { RobotsListViewBadgesCondainer } from "./RobotsListViewBadgesCondainer";
import { dateToHourDate } from "../../tools";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RobotModal } from "./RobotModal";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const RobotsListView = (props: RobotsListViewProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      {props.isWorking && (
        <div className="col-12 item-list-loading text-center">
          <Loading size={120} />
        </div>
      )}

      {!props.isWorking && (
        <div className="row">
          <h5 className="pt-4 fw-bold">
            Robots List
            <TooltipButton
              className="ms-1"
              tooltip={t("adminPanel.robots.actionBar.new")}
              onClick={() => setIsModalOpen(true)}
            >
              <PlaylistAddIcon className="color-cleanfix" />
            </TooltipButton>
            <TooltipButton
              className="ms-1"
              tooltip={t("adminPanel.robots.actionBar.refresh")}
              onClick={props.onRefresh}
            >
              <RefreshRoundedIcon className="color-cleanfix" />
            </TooltipButton>
          </h5>

          <RobotsListViewBadgesCondainer
            filter={props.filter}
            onBadgeChange={props.onBadgeChange}
          />

          <TableGeneric
            columns={columnsDefinition}
            items={props.items}
            idProperty="id"
            headerClick={(header, itemsOrder) =>
              props.onDataOrderChange(itemsOrder)
            }
            itemClick={(item) => props.onCurrentIdChange(item.id)}
            currentId={props.currentId}
            itemsOrder={props.dataOrder}
          />
        </div>
      )}

      {isModalOpen && (
        <RobotModal
          onClose={() => setIsModalOpen(false)}
          onSave={props.onRobotAdded}
          robotDefaultConfiguration={props.robotDefaultConfiguration}
        />
      )}
    </>
  );
};

interface RobotsListViewProps {
  items: RobotAdminListDto[];
  dataOrder: OrderDto;
  filter: RobotAdminFilterCriteria;
  onDataOrderChange: (dataOrder: OrderDto) => void;
  onBadgeChange: (selection: RobotAdminFilterCriteria) => void;
  currentId: string | undefined;
  isWorking: boolean;
  onCurrentIdChange: (currentId: string | undefined) => void;
  onRobotAdded: () => void;
  onRefresh: () => void;
  robotDefaultConfiguration: RobotDefaultConfigurationDto | undefined;
}

const columnsDefinition: tableGenericColumn[] = [
  {
    canSort: false,
    itemClassName: "td-1-icon pe-0",
    cellTemplate: (item: RobotAdminListDto) => (
      <>
        {item.cloudConnection ? (
          <TooltipContainer text={dateToHourDate(item.periodicUpdateTime)}>
            <WifiRoundedIcon className="on-line me-2" />
          </TooltipContainer>
        ) : (
          <TooltipContainer
            text={
              item.periodicUpdateTime
                ? dateToHourDate(item.periodicUpdateTime)
                : i18n.t("adminPanel.robots.notConnected")
            }
          >
            <WifiOffRoundedIcon className="off-line me-2" />
          </TooltipContainer>
        )}
      </>
    ),
  },
  {
    title: i18n.t("adminPanel.robots.list.cleanfixSerialNumber"),
    property: "cleanfixSerialNumber",
    canSort: false,
  },
  {
    title: i18n.t("adminPanel.robots.list.name"),
    property: "name",
    canSort: false,
  },
  {
    title: i18n.t("adminPanel.robots.list.tenant"),
    property: "tenant",
    canSort: false,
  },
  {
    title: i18n.t("adminPanel.robots.list.type"),
    canSort: false,
    cellTemplate: (item: RobotAdminListDto) =>
      item.robotType ? (
        <span>{item.robotType}</span>
      ) : (
        <WarningAmberIcon className="no-property" />
      ),
  },
  {
    title: i18n.t("adminPanel.robots.list.license"),
    canSort: false,
    cellTemplate: (item: RobotAdminListDto) =>
      item.license ? (
        <span>{item.license}</span>
      ) : (
        <WarningAmberIcon className="no-property" />
      ),
  },
];
