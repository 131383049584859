import { Paginator, PaginatorProps } from "../../../components/paginatior";
import "./DashboardItemsListDetail.scss";
import { ReactElement } from "react";

export const DashboardItemsListDetail = (
  props: DashboardItemsListDetailProps,
) => {
  return (
    <div className="row dashboard-items-list-detail">
      <div className={`col-7 left-side ${props.leftSide.className}`}>
        <div className="row">
          <div className="col-12 item-list-container pb-1">
            {props.leftSide.component}
          </div>
          <div className="col-12 pager-container pt-2">
            <Paginator
              itemsPerPage={props.paginator.itemsPerPage}
              currentPage={props.paginator.currentPage}
              totalItems={props.paginator.totalItems}
              onNextPage={props.paginator.onNextPage}
              onPreviousPage={props.paginator.onPreviousPage}
              onItemsPerPageChange={props.paginator.onItemsPerPageChange}
            />
          </div>
        </div>
      </div>
      <div className={`col-5 right-side ${props.rightSide.className}`}>
        <div className="row robots-details-height">
          <div className="col-12  item-view-container">
            {props.rightSide.component}
          </div>
        </div>
      </div>
    </div>
  );
};

interface DashboardItemsListDetailProps {
  leftSide: ItemsListDetailComponent;
  rightSide: ItemsListDetailComponent;
  paginator: PaginatorProps;
}

interface ItemsListDetailComponent {
  className: string;
  component: ReactElement;
}
