import { SortOrder } from "./resource-models";

export interface OrderDto {
  orderColumns: string;
  order: SortOrder;
}

export const orderDtoDefault: OrderDto = {
  orderColumns: "",
  order: "asc",
};

export interface ErrorData {
  property: string;
  message: string;
}
