import "./App.scss";
import {
  MsalAuthenticationTemplate,
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { authProvider } from "./services/auth";
import { BrowserRouter } from "react-router-dom";
import { Unauthenticated } from "./views/auth";
import { Main } from "./views/main";
import { ToastContainer } from "react-toastify";
import { InteractionType } from "@azure/msal-browser";

export const App = () => (
  <>
    <MsalProvider instance={authProvider}>
      <BrowserRouter>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <UnauthenticatedTemplate>
            <Unauthenticated />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Main />
          </AuthenticatedTemplate>
        </MsalAuthenticationTemplate>
      </BrowserRouter>
    </MsalProvider>

    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={true}
      rtl={false}
      theme="colored"
    />
  </>
);

export default App;
