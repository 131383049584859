import { ExecutionInformationDto } from "../../../models";
import { axiosGet, axiosPost } from "../../../services";

const url = "admin/v2/AzureAd";

export const getExecutionInfo = async () => {
  return (await axiosGet(`${url}/execution-info`))
    .data as ExecutionInformationDto;
};

export const postSynchronize = async () => {
  await axiosPost(`${url}/synchronize`, null);
};
