import { useTranslation } from "react-i18next";
import { useState } from "react";
import { TextValue } from "./common";
import { RobotAdminDto } from "../../../models";
import "./RobotViewSim.scss";
import { RobotViewSimAssignModal } from "./RobotViewSimAssignModal";

export const RobotViewSimNoAssigned = ({
  robotAdmin,
  refresh,
}: RobotViewSimProp) => {
  const { t } = useTranslation();

  const [confirmAction, setConfirmAction] = useState<"AddSim" | undefined>(
    undefined,
  );

  return (
    <div>
      <TextValue
        text={t("adminPanel.robots.sim.noSimCardConnected")}
        value={""}
      />
      <div className="col-12 text-end mb-3 mt-4">
        <button
          className="btn btn-outline-cleanfix-no-border"
          onClick={() => setConfirmAction("AddSim")}
        >
          {t("adminPanel.robots.sim.simActions.addSim")}
        </button>
      </div>

      {confirmAction === "AddSim" && (
        <RobotViewSimAssignModal
          robotId={robotAdmin.id}
          cleanfixSerialNumber={robotAdmin.cleanfixSerialNumber}
          routerSerialNumber={robotAdmin.routerSerialNumber}
          onClose={() => {
            setConfirmAction(undefined);
          }}
          assigned={refresh}
        />
      )}
    </div>
  );
};

interface RobotViewSimProp {
  robotAdmin: RobotAdminDto;
  refresh: () => Promise<void>;
}
