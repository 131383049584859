import "./Dashboard.scss";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { TitleBar } from "../../components";
import { DashboardMissionsView } from "./DashboardMissionsView";
import { DashboardFleetView } from "./DashboardFleetView";
import { useState } from "react";
import { DashboardRobotsView } from "./DashboardRobotsView";
import { DashboardRefreshModal } from "./DashboardRefreshModal";

export const Dashboard = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");
  const [filterButtonEventTrigger, setFilterButtonEventTrigger] = useState<
    boolean | undefined
  >(undefined);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isRefreshedModalOpen, setIsRefreshedModalOpen] = useState(false);
  const [
    isToggleInvalidMissionsActivated,
    setIsToggleInvalidMissionsActivated,
  ] = useState(false);

  const [fleetStatisticsCreatedAt, setFleetStatisticsCreatedAt] = useState<
    Date | undefined
  >(undefined);
  const [missionsStatisticsCreatedAt, setMissionsStatisticsCreatedAt] =
    useState<Date | undefined>(undefined);
  const [robotsStatisticsCreatedAt, setRobotsStatisticsCreatedAt] = useState<
    Date | undefined
  >(undefined);

  const handleRefresh = () => {
    if (refreshCounter < 0) {
      setRefreshCounter(1);
    } else {
      setRefreshCounter(refreshCounter + 1);
    }
  };

  return (
    <div className="row">
      <TitleBar
        title={t("adminPanel.dashboard.title")}
        textSearch={setSearchText}
        textSearchToolTip={t("adminPanel.robots.textSearchToolTip")}
        canFilterButton={true}
        filterButtonToolTip={t("adminPanel.robots.filterButtonToolTip")}
        filterButtonClick={() => {
          setFilterButtonEventTrigger((prev) => !prev);
        }}
        canRefreshButton={true}
        refreshButtonClick={() => setIsRefreshedModalOpen(true)}
        refreshButtonToolTip={"Refresh the dashboard cache"}
        canToggleInvalidMissionsButton={true}
        toggleInvalidMissionsButtonToolTip={
          "Toggle invalid missions (cleanedSurface = 0 and Status is [FailedTimeout])"
        }
        toggleInvalidMissionsButtonClick={() => {
          // set RefreshCounter to 0 so that the cache will be used
          setRefreshCounter(0);
          setIsToggleInvalidMissionsActivated((prev) => !prev);
        }}
        isToggleInvalidMissionsActivated={isToggleInvalidMissionsActivated}
      />

      <div className="pe-4 mt-4">
        <div className="row me-3">
          <div className="col-12 col-lg-6 pb-4">
            <Box
              className="col-12 ms-3 mb-4 dashboard-component-border"
              sx={{ minWidth: "25vh", minHeight: "50%" }}
            >
              <DashboardMissionsView
                refreshCounter={refreshCounter}
                setMissionStatisticsCreatedAt={setMissionsStatisticsCreatedAt}
                toggleInvalidMissions={isToggleInvalidMissionsActivated}
              />
            </Box>
            <Box
              className="col-12 ms-3 dashboard-component-border"
              sx={{ minWidth: "25vh", minHeight: "50%" }}
            >
              <DashboardFleetView
                refreshCounter={refreshCounter}
                setFleetStatisticsCreatedAt={setFleetStatisticsCreatedAt}
                toggleInvalidMissions={isToggleInvalidMissionsActivated}
              />
            </Box>
          </div>

          <div className="col-12 col-lg-6">
            <Box
              className="col-12 h-100 ms-3 me-3 dashboard-component-border"
              sx={{ minWidth: "25vh", minHeight: "100%" }}
            >
              <DashboardRobotsView
                searchText={searchText}
                filterButtonEventTrigger={filterButtonEventTrigger}
                refreshCounter={refreshCounter}
                setRobotsStatisticsCreatedAt={setRobotsStatisticsCreatedAt}
                toggleInvalidMissions={isToggleInvalidMissionsActivated}
              />
            </Box>
          </div>
        </div>
      </div>
      {isRefreshedModalOpen && (
        <DashboardRefreshModal
          onClose={() => setIsRefreshedModalOpen(false)}
          refreshed={handleRefresh}
          fleetStatisticsCreatedAt={fleetStatisticsCreatedAt}
          missionsStatisticsCreatedAt={missionsStatisticsCreatedAt}
          robotsStatisticsCreatedAt={robotsStatisticsCreatedAt}
        />
      )}
    </div>
  );
};
