import "./Dashboard.scss";
import { Point, ResponsiveLine } from "@nivo/line";
import { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getFleetStatisticsData } from "./Dashboard.service";
import { FleetActivityStatisticsDto, StatisticsCountDto } from "../../models";
import { getFirstDayOf6MonthsAgo } from "../../tools";
import { ColorMappingMissionCount, colorMappingMissionCount } from "./Types";
import { Loading } from "../../components";
import { formatWithApostrophes } from "../../tools/number";

interface FleetPoint {
  x: string;
  y: number;
}

interface FleetData {
  id: string;
  color: string;
  data: FleetPoint[];
}

const formatYPoint = (point: Point) => {
  return point.serieId === "Mission Count"
    ? `${formatWithApostrophes(Number(point.data.y))} Missions`
    : point.serieId === "Cleaning Time"
    ? `${formatWithApostrophes(Number(point.data.y))} h`
    : `${formatWithApostrophes(Number(point.data.y) * 100)} m²`;
};

export const DashboardFleetView = ({
  refreshCounter,
  setFleetStatisticsCreatedAt,
  toggleInvalidMissions,
}: {
  refreshCounter: number;
  setFleetStatisticsCreatedAt: (date: Date) => void;
  toggleInvalidMissions: boolean;
}) => {
  const { t } = useTranslation();
  const [robotFleetStatistics, setRobotFleetStatistics] =
    useState<FleetActivityStatisticsDto | null>(null);
  const [failedReasons, setFailedReasons] = useState<
    StatisticsCountDto[] | null
  >(null);
  const [fleetStatistic, setFleetStatistic] = useState<FleetData[]>([]);
  const [unselectedKeys, setUnselectedKeys] = useState<string[]>([]);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [hideLegend, setHideLegend] = useState(false);

  useEffect(() => {
    const getStatisticData = async (
      statisticsSinceDate: string,
      activityThresholdDays: number,
      failedReasonsForLastXDays: number,
    ) => {
      try {
        const result = await getFleetStatisticsData(
          statisticsSinceDate,
          activityThresholdDays,
          failedReasonsForLastXDays,
          refreshCounter <= 0, // when refresh button was clicked, do not use cache
          toggleInvalidMissions,
        );
        if (result) {
          setRobotFleetStatistics(result.robotFleetStatistics);
          setFailedReasons(result.failedReasons);

          const totalAreaCleaned: FleetPoint[] = [];
          const totalCleaningTime: FleetPoint[] = [];
          const totalMissions: FleetPoint[] = [];

          const fleets: FleetData[] = [];

          result.fleetStatisticPerMonth.forEach((e) => {
            if (
              e.totalAreaCleaned !== null &&
              e.totalCleaningTime !== null &&
              e.totalMissions !== null
            ) {
              totalAreaCleaned.push({
                x: e.month,
                y: e.totalAreaCleaned / 100,
              });
              totalCleaningTime.push({
                x: e.month,
                y: e.totalCleaningTime / 60,
              });
              totalMissions.push({
                x: e.month,
                y: e.totalMissions,
              });
            }
          });

          fleets.push({
            id: "Area Cleaned",
            color: "hsl(6, 70%, 50%)",
            data: totalAreaCleaned,
          });

          fleets.push({
            id: "Cleaning Time",
            color: "hsl(236, 70%, 50%)",
            data: totalCleaningTime,
          });

          fleets.push({
            id: "Mission Count",
            color: "hsl(282, 70%, 50%)",
            data: totalMissions,
          });

          setFleetStatistic(fleets);
          setFleetStatisticsCreatedAt(new Date(result.statisticsCreatedAt));
        }
      } catch (error) {
        console.error("Error fetching fleet statistics data", error);
      }
    };

    setFleetStatistic([]);
    setFailedReasons(null);

    getStatisticData(getFirstDayOf6MonthsAgo(), 6, 30);
  }, [refreshCounter, setFleetStatisticsCreatedAt, toggleInvalidMissions]);

  const getFilteredFleetStatistic = useMemo((): FleetData[] => {
    return fleetStatistic.filter((item) => !unselectedKeys.includes(item.id));
  }, [fleetStatistic, unselectedKeys]);

  const colorMapping: Record<string, string> = {
    "Mission Count": "#4682b4",
    "Cleaning Time": "#5CB270",
    "Area Cleaned": "#E9680E",
  };

  const handleLegendClick = (id: string) => {
    setUnselectedKeys((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  return (
    <div className="dashboard-container">
      <div className="row">
        <div className="col-12 mt-3 mb-3">
          <span className="dashboard-component-title">
            {t("adminPanel.dashboard.fleet.title")}{" "}
          </span>
        </div>
      </div>

      {robotFleetStatistics !== null && failedReasons !== null ? (
        <div className="row">
          <div
            className={
              hideLegend
                ? "col-7 mt-1 mb-0 bar-height-fleet-full"
                : "col-7 mt-1 mb-0 bar-height"
            }
            ref={chartContainerRef}
          >
            <div className="col-7 dashboard-component-subtitle">
              Fleet Summary
            </div>
            <ResponsiveLine
              data={getFilteredFleetStatistic}
              margin={{ top: 20, right: 40, bottom: 50, left: 70 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: 0,
                max: "auto",
                stacked: false,
              }}
              yFormat=" >-.2~f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legend: "",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "100 m², h, Count",
                legendOffset: -60,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabel="y"
              pointLabelYOffset={-12}
              enableArea={true}
              enablePoints={true}
              enableGridX={true}
              enableGridY={true}
              enableSlices="x"
              debugMesh={false}
              debugSlices={false}
              enableCrosshair={true}
              crosshairType="cross"
              role="img"
              defs={[]}
              fill={[]}
              isInteractive={true}
              useMesh={true}
              lineWidth={2}
              curve="linear"
              layers={[
                "grid",
                "markers",
                "areas",
                "lines",
                "slices",
                "points",
                "axes",
                "legends",
              ]}
              tooltip={({}) => <></>}
              colors={({ id }) => colorMapping[id as string] || "#000000"}
              legends={[]}
              enablePointLabel={false}
              areaOpacity={0.2}
              areaBlendMode="normal"
              areaBaselineValue={0}
              sliceTooltip={({ slice }) => (
                <div className="slice-tooltip">
                  {slice.points.map((point) => (
                    <div key={point.id} className="tooltip-row-flex">
                      <div
                        className="tooltip-color-box"
                        style={{
                          backgroundColor:
                            colorMapping[point.serieId] || "#000000",
                        }}
                      ></div>
                      {point.serieId}: {formatYPoint(point)}
                    </div>
                  ))}
                </div>
              )}
              onClick={() => setHideLegend(!hideLegend)}
            />
            {!hideLegend && (
              <div className="legend-container">
                {fleetStatistic.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => handleLegendClick(item.id)}
                    className={`legend-item ${
                      unselectedKeys.includes(item.id)
                        ? "legend-item--inactive"
                        : ""
                    }`}
                  >
                    <div
                      className="legend-color-box"
                      style={{
                        backgroundColor: colorMapping[item.id] || "#000000",
                      }}
                    ></div>
                    <span>{item.id}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="col-5 mt-1 ms-0 p-0 bar-height-fleet-statistics">
            <div className="container">
              <div className="col-12 mb-2 mx-0 dashboard-component-subtitle">
                Current Number of Robots
              </div>
              <div className="row dashboard-component-labels me-1">
                <div className="col-6">
                  <div>&#8203;</div>
                  <div>Total</div>
                  <div>Active</div>
                  <div>Inactive</div>
                </div>

                <div className="col-4 me-0 pe-0 text-end">
                  <div>XL</div>
                  <div>
                    {robotFleetStatistics.activeXl +
                      robotFleetStatistics.inactiveXl}
                  </div>
                  <div>{robotFleetStatistics.activeXl}</div>
                  <div>{robotFleetStatistics.inactiveXl}</div>
                </div>

                <div className="col-2 me-0 pe-0 text-end">
                  <div>M</div>
                  <div>
                    {robotFleetStatistics.activeM +
                      robotFleetStatistics.inactiveM}
                  </div>
                  <div>{robotFleetStatistics.activeM}</div>
                  <div>{robotFleetStatistics.inactiveM}</div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="mt-4 mb-3 dashboard-component-subtitle">
                Failed Reasons Last 30 Days
              </div>
              <div className="row mt-1 dashboard-component-labels">
                {failedReasons.map(
                  (e) =>
                    e.count !== 0 && (
                      <div
                        key={e.name}
                        className="col-12 d-flex justify-content-between mb-1"
                      >
                        <div className="tooltip-row-flex">
                          <div
                            className="legend-color-box"
                            style={{
                              backgroundColor:
                                colorMappingMissionCount[
                                  e.name as keyof ColorMappingMissionCount
                                ] || "#FFFFFF",
                            }}
                          ></div>
                          {e.name.slice(0, 20)}
                        </div>
                        <div className="me-1">{e.count}</div>
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bar-height">
          <Loading size={120} />
        </div>
      )}
    </div>
  );
};
