import CodeIcon from "@mui/icons-material/Code";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { TooltipButton } from "../../../components";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { RobotViewContext } from "./RobotViewContext";
import { RobotModal } from "../RobotModal";
import { RobotAccessSettingsModal } from "./RobotAccessSettingsModal";
import { RobotDeleteModal } from "./RobotDeleteModal";

export const RobotActionBar = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAccessSettingsModalOpen, setIsAccessSettingsModalOpen] =
    useState(false);

  const { t } = useTranslation();
  const { robotAdmin, refresh, refreshList } = useContext(RobotViewContext);
  if (!robotAdmin) return <></>;

  const copyLinkToItem = async () => {
    navigator.clipboard.writeText(
      `${window.location.href.split("?")[0]}?id=${robotAdmin.id}`,
    );
  };
  return (
    <div className="row pt-2 robot-action-bar">
      <div className=" d-flex">
        <div className="me-3">
          <TooltipButton
            tooltip={t("adminPanel.robots.actionBar.refresh")}
            onClick={refresh}
          >
            <RefreshRoundedIcon className="color-cleanfix" />
          </TooltipButton>
        </div>
        <div className="me-3">
          <TooltipButton
            tooltip={t("adminPanel.robots.actionBar.accessSettings")}
            onClick={() => setIsAccessSettingsModalOpen(true)}
          >
            <CodeIcon className="color-cleanfix" />
          </TooltipButton>
        </div>
        <div className="me-3">
          <TooltipButton
            tooltip={t("adminPanel.robots.actionBar.copyLink")}
            onClick={copyLinkToItem}
          >
            <InsertLinkIcon className="color-cleanfix" />
          </TooltipButton>
        </div>
        <div className="me-3">
          <TooltipButton
            tooltip={t("adminPanel.robots.actionBar.edit")}
            onClick={() => setIsEditModalOpen(true)}
          >
            <ModeEditOutlineIcon className="color-cleanfix" />
          </TooltipButton>
        </div>

        <div className="me-3">
          <TooltipButton
            tooltip={t("adminPanel.robots.actionBar.deleteRobot")}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <DeleteForeverOutlinedIcon className="color-delete" />
          </TooltipButton>
        </div>

        {isEditModalOpen && (
          <RobotModal
            onClose={() => setIsEditModalOpen(false)}
            onSave={(x) => {
              refresh();
              refreshList(x);
            }}
            robotAdmin={robotAdmin}
            robotDefaultConfiguration={undefined}
          />
        )}

        {isAccessSettingsModalOpen && (
          <RobotAccessSettingsModal
            robotId={robotAdmin.id}
            onClose={() => setIsAccessSettingsModalOpen(false)}
          />
        )}

        {isDeleteModalOpen && (
          <RobotDeleteModal
            robotId={robotAdmin.id}
            cleanfixSerialNumber={robotAdmin.cleanfixSerialNumber}
            onClose={() => setIsDeleteModalOpen(false)}
            deleted={refreshList}
          />
        )}
      </div>
    </div>
  );
};
