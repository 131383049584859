import { useMsal } from "@azure/msal-react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useState } from "react";
import { MenuOptionConfig } from "../MenuOptions";
import { MenuOption } from "./MenuOption";
import { useTranslation } from "react-i18next";

export const Logout = (props: LogoutProps) => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const [menuOption] = useState<MenuOptionConfig>({
    icon: <ExitToAppIcon />,
    text: t("adminPanel.logout"),
  });

  const logout = () => {
    instance.logout();
  };
  return (
    <div className="col-12 px-0">
      <MenuOption
        option={menuOption}
        currentPath="---"
        onClick={logout}
        isExpanded={props.isExpanded}
      />
    </div>
  );
};

interface LogoutProps {
  isExpanded: boolean;
}
