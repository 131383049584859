import { useState } from "react";
import { Footer } from "./Footer";
import { Logout } from "./Logout";
import { MenuContainer } from "./MenuContainer";
import { UserContainer } from "./UserContainer";

export const LeftSidePanel = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div
      className={`row left-side-panel ${
        isExpanded ? "" : "left-side-panel-small"
      }`}
    >
      <UserContainer isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <MenuContainer isExpanded={isExpanded} />
      <Logout isExpanded={isExpanded} />
      {isExpanded && <Footer />}
    </div>
  );
};
