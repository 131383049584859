import { RobotCucItemStatus } from "./RobotCucItemStatus";
import { useTranslation } from "react-i18next";
import { CleaningDevice } from "../../../models/resource-models";

interface ControllerCleaningDeviceStatusProp {
  label: string;
  item?: CleaningDevice;
}
export const RobotCucCleaningDeviceStatus = ({
  label,
  item,
}: ControllerCleaningDeviceStatusProp) => {
  const { t } = useTranslation();
  return (
    <RobotCucItemStatus
      healthy={item?.healthy ?? false}
      label={label}
      state={t(
        `check.cuc.enums.${item?.state ?? "NoAvailableData"}`,
      )?.toString()}
      errorEnum={item?.error.toString()}
      remark={item ? `${t(`check.cuc.${item?.active.toString()}`)}` : ""}
    />
  );
};
