import { Link } from "react-router-dom";
import { MenuOptionConfig } from "../MenuOptions";

export const MenuOption = (props: MenuOptionProps) => {
  const currentNodeStyle =
    props.option.path &&
    props.currentPath.toLowerCase() === props.option.path.toLowerCase()
      ? "menu-item-current"
      : "";

  const optionClick = () => {
    if (!props.onClick) return;
    props.onClick(props.option);
  };

  return (
    <>
      {props.isExpanded && (
        <Link to={props.option.path ?? ""} onClick={optionClick}>
          <table className="table table-borderless menu-item mb-0">
            <tbody>
              <tr>
                <td className={`col-icon ${currentNodeStyle}`}>
                  {props.option.image && (
                    <img
                      src={props.option.image}
                      className="icon"
                      alt={props.option.text}
                    />
                  )}
                  {props.option.icon && props.option.icon}
                </td>
                <td className={`align-middle ${currentNodeStyle}`}>
                  {props.option.text}
                </td>
              </tr>
            </tbody>
          </table>
        </Link>
      )}
      {!props.isExpanded && (
        <Link to={props.option.path ?? ""} onClick={optionClick}>
          <table className="table table-borderless menu-item">
            <tbody>
              <tr>
                <td className={`col-icon ${currentNodeStyle}`}>
                  {props.option.image && (
                    <img
                      src={props.option.image}
                      className="icon"
                      alt={props.option.text}
                    />
                  )}
                  {props.option.icon && props.option.icon}
                </td>
              </tr>
            </tbody>
          </table>
        </Link>
      )}
    </>
  );
};

interface MenuOptionProps {
  option: MenuOptionConfig;
  currentPath: string;
  isExpanded: boolean;
  onClick?: (option: MenuOptionConfig) => void;
}
