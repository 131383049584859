import { RobotAdminDto } from "../../../models";

export const uiConfigHelper = (robotAdmin: RobotAdminDto | undefined) => {
  if (!robotAdmin) return uiConfigDefault;

  const hideMissionEndBehavior =
    robotAdmin.features.find((i) => i.name === "uiSettingMissionEndBehavior")
      ?.action === "hide";

  const hideMissionChargingBehaviorAutomatic =
    robotAdmin.features.find(
      (i) => i.name === "uiSettingMissionChargingBehaviorAutomatic",
    )?.action === "hide";

  const disabledServerControl =
    robotAdmin.features.find((i) => i.name === "uiServerDisabledControl")
      ?.action === "disabled";

  const enabledServerModeToggle =
    robotAdmin.features.find((i) => i.name === "uiSettingsServerMode")
      ?.action === "enabled";

  const pairingChargingStationEnabled =
    robotAdmin.features.find((i) => i.name === "uiPairingChargingStation")
      ?.action === "enabled";

  const isDisabledControl =
    robotAdmin.settings.robotType === "ra660NaviXl" &&
    robotAdmin.settings.license === "server" &&
    robotAdmin.settings.antServerModeEnabled &&
    disabledServerControl;

  return {
    hideMissionEndBehavior,
    hideMissionChargingBehaviorAutomatic,
    disabledServerControl,
    enabledServerModeToggle,
    pairingChargingStationEnabled,
    isDisabledControl,
  };
};

export interface UiConfig {
  hideMissionEndBehavior: boolean;
  hideMissionChargingBehaviorAutomatic: boolean;
  disabledServerControl: boolean;
  enabledServerModeToggle: boolean;
  pairingChargingStationEnabled: boolean;
  isDisabledControl: boolean;
}

export const uiConfigDefault: UiConfig = {
  hideMissionEndBehavior: false,
  hideMissionChargingBehaviorAutomatic: false,
  disabledServerControl: false,
  enabledServerModeToggle: false,
  pairingChargingStationEnabled: false,
  isDisabledControl: false,
};
