import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const UserContainer = (props: UserContainerProps) => {
  const [activeAccount, setActiveAccount] = useState<AccountInfo | null>(null);
  const { instance } = useMsal();

  useEffect(() => {
    const account = instance.getActiveAccount();
    if (account) {
      setActiveAccount(account);
      return;
    }

    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      setActiveAccount(accounts[0]);
    }
  }, [instance]);

  return (
    <div className="col-12 user-container pe-1">
      <div className="d-flex h-100">
        {props.isExpanded && (
          <>
            <div className="flex-fill text-no-overflow align-self-center">
              <strong>{activeAccount?.name}</strong>
            </div>

            <div className="align-self-center">
              <button
                className="btn btn-icon-button"
                onClick={() => props.setIsExpanded(false)}
              >
                <ChevronLeftIcon />
              </button>
            </div>
          </>
        )}

        {!props.isExpanded && (
          <div className="align-self-center">
            <button
              className="btn btn-icon-button"
              onClick={() => props.setIsExpanded(true)}
            >
              <ChevronRightIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

interface UserContainerProps {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}
