import { useLocation } from "react-router";
import { MenuOptionsConfig } from "../MenuOptions";
import { MenuOption } from "./MenuOption";

export const MenuContainer = (props: MenuContainerProps) => {
  const location = useLocation();

  return (
    <div
      className={`col-12 ${
        props.isExpanded ? "menu-container" : "menu-container-not-expanded"
      }  px-0`}
    >
      {MenuOptionsConfig.map((x) => (
        <MenuOption
          key={x.text}
          option={x}
          currentPath={location.pathname}
          isExpanded={props.isExpanded}
        />
      ))}
    </div>
  );
};

interface MenuContainerProps {
  isExpanded: boolean;
}
