import { NativeSelect } from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { TooltipContainer } from "../tooltipContainer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const Paginator = (props: PaginatorProps) => {
  const [currentInterval, setCurrentInterval] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const start = (props.currentPage - 1) * props.itemsPerPage;
    const end = start + props.itemsPerPage;
    setCurrentInterval(
      `${start + 1}-${end > props.totalItems ? props.totalItems : end}`,
    );
  }, [props.currentPage, props.itemsPerPage, props.totalItems]);

  const previousPage = () => {
    if (props.currentPage === 1) {
      return;
    }
    props.onPreviousPage();
  };

  const nextPage = () => {
    if (props.currentPage * props.itemsPerPage >= props.totalItems) {
      return;
    }
    props.onNextPage();
  };

  return (
    <div className="row paginator-container">
      <div className="col">
        <TooltipContainer text={t("adminPanel.paginator.rowPerPage")}>
          <NativeSelect
            value={props.itemsPerPage}
            disableUnderline={true}
            onChange={(event) =>
              props.onItemsPerPageChange(parseInt(event.target.value, 10))
            }
          >
            {rowsPerPage.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </NativeSelect>
        </TooltipContainer>
      </div>
      <div className="col text-end align-self-center">
        <span className="me-2">
          {currentInterval} {t("adminPanel.paginator.of")} {props.totalItems}
        </span>
        <TooltipContainer text={t("adminPanel.paginator.previousPage")}>
          <ChevronLeftOutlinedIcon
            className="icon-button"
            onClick={previousPage}
          />
        </TooltipContainer>
        <TooltipContainer text={t("adminPanel.paginator.nextPage")}>
          <ChevronRightOutlinedIcon
            className="icon-button"
            onClick={nextPage}
          />
        </TooltipContainer>
      </div>
    </div>
  );
};

const rowsPerPage = [5, 10, 25, 50, 100];

export interface PaginatorProps {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
}
