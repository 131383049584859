import { filterBarSubscriptionManager } from "../../components";
import {
  RobotAdminFilterCriteria,
  RobotLicense,
  RobotType,
} from "../../models";
import { removeToStringArray } from "../../tools";

export const RobotsListViewBadgesCondainer = (
  props: RobotsListViewBadgesCondainerProps,
) => {
  return (
    <div className="badge-container">
      {props.filter.searchText && (
        <span
          className="badge rounded-pill"
          onClick={() => filterBarSubscriptionManager.setData("")}
        >
          {props.filter.searchText}
        </span>
      )}
      {props.filter.robotIds?.map((x) => (
        <span
          key={`robotIds_${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              robotIds: removeToStringArray(props.filter.robotIds, x),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}
      {props.filter.robotNames?.map((x) => (
        <span
          key={`robotNames_${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              robotNames: removeToStringArray(props.filter.robotNames, x),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.robotLocations?.map((x) => (
        <span
          key={`robotLocations${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              robotLocations: removeToStringArray(
                props.filter.robotLocations,
                x,
              ),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.antSerialNumbers?.map((x) => (
        <span
          key={`antSerialNumbers${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              antSerialNumbers: removeToStringArray(
                props.filter.antSerialNumbers,
                x,
              ),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.cleanfixSerialNumbers?.map((x) => (
        <span
          key={`cleanfixSerialNumbers${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              cleanfixSerialNumbers: removeToStringArray(
                props.filter.cleanfixSerialNumbers,
                x,
              ),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.licenses?.map((x) => (
        <span
          key={`licenses${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              licenses: removeToStringArray(
                props.filter.licenses,
                x,
              ) as RobotLicense[],
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.robotTypes?.map((x) => (
        <span
          key={`robotTypes${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              robotTypes: removeToStringArray(
                props.filter.robotTypes,
                x,
              ) as RobotType[],
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.tenants?.map((x) => (
        <span
          key={`tenants${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              tenants: removeToStringArray(props.filter.tenants, x),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.antVersions?.map((x) => (
        <span
          key={`antVersions${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              antVersions: removeToStringArray(props.filter.antVersions, x),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}

      {props.filter.hmiVersions?.map((x) => (
        <span
          key={`hmiVersions${x}`}
          className="badge rounded-pill"
          onClick={() => {
            const f = {
              ...props.filter,
              hmiVersions: removeToStringArray(props.filter.hmiVersions, x),
            };
            props.onBadgeChange(f);
          }}
        >
          {x}
        </span>
      ))}
    </div>
  );
};

interface RobotsListViewBadgesCondainerProps {
  filter: RobotAdminFilterCriteria;
  onBadgeChange: (selection: RobotAdminFilterCriteria) => void;
}
