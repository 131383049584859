import {
  HubConnection,
  HubConnectionBuilder,
  HttpTransportType,
} from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { getBaseUrl } from "../services/Axios.services";
import { RobotCucDto } from "../models";
import { acquireAccessToken, authProvider } from "../services";

export const useCloudApiHub = (robotId?: string) => {
  const [hubConnection, setHubConnection] = useState<
    HubConnection | undefined
  >();

  useEffect(() => {
    if (!robotId) return;

    const getHubConnection = (robotId: string) => {
      const baseSignalRUrl = `${getBaseUrl()}hubs/cloudApiHub`;

      const hubConnection = new HubConnectionBuilder()
        .withUrl(`${baseSignalRUrl}?robotId=${robotId}`, {
          withCredentials: true,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: async (): Promise<string> =>
            (await acquireAccessToken(authProvider)) ?? "",
        })
        .withAutomaticReconnect()
        .build();

      return hubConnection;
    };

    const connection = getHubConnection(robotId);
    setHubConnection(connection);

    return () => {
      connection.stop();
    };
  }, [robotId]);

  return hubConnection;
};

export const addRobotControlListener = (
  hubConnection: HubConnection,
  message: string,
  callback: (robotControl: RobotCucDto) => void,
) => {
  hubConnection
    .start()
    .catch((error) => console.error(error))
    .then(() => {
      hubConnection.on(message, callback);
    });
};
