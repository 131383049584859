import { useState, useEffect } from "react";
import { RobotInformation } from "./RobotInformation";
import { RobotAdminDto } from "../../../models";
import { getRobot } from "../robots.service";
import { RobotActionBar } from "./RobotActionBar";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { RobotViewTabs } from "./RobotViewTabs";
import { RobotViewContext } from "./RobotViewContext";
import { UiConfig, uiConfigDefault, uiConfigHelper } from "./RobotViewUiConfig";
import { Loading } from "../../../components";

export const RobotView = (props: RobotViewProps) => {
  const [robotAdmin, setRobotAdmin] = useState<RobotAdminDto | undefined>(
    undefined,
  );
  const [isWorking, setIsWorking] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [uiConfig, setUiConfig] = useState<UiConfig>({ ...uiConfigDefault });

  useEffect(() => {
    if (props.currentId === robotAdmin?.id) return;

    if (!props.currentId) {
      setRobotAdmin(undefined);
      return;
    }

    getData(props.currentId);
  }, [props, robotAdmin]);

  const getData = async (id: string) => {
    setIsWorking(true);

    try {
      const robot = await getRobot(id);
      setRobotAdmin(robot);
      setUiConfig(uiConfigHelper(robot));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const robotRefresh = async () => {
    if (!props.currentId) return;

    await getData(props.currentId);
  };

  if (!robotAdmin) return <></>;

  return (
    <>
      {isWorking && (
        <div className="col-12 item-list-loading text-center h-100">
          <Loading size={120} />
        </div>
      )}

      {!isWorking && (
        <RobotViewContext.Provider
          value={{
            robotAdmin,
            setRobotAdmin,
            refresh: robotRefresh,
            currentTab,
            setCurrentTab,
            uiConfig,
            setUiConfig,
            refreshList: props.onRefreshList,
          }}
        >
          <RobotInformation />
          <RobotActionBar />
          <RobotViewTabs />
        </RobotViewContext.Provider>
      )}
    </>
  );
};

interface RobotViewProps {
  currentId: string | undefined;
  onRefreshList: (id?: string) => void;
}
