import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RobotViewContext } from "./RobotViewContext";
import { SubTitle, TextContainer, TextValue } from "./common";
import { RobotAdminSettingsDto, RobotTimeZone } from "../../../models";
import {
  Loading,
  SwitchInput,
  TextInput,
  TooltipContainer,
} from "../../../components";
import { resources } from "../../../i18n/config";
import { NativeSelect, Radio } from "@mui/material";
import { getTimeZones, patchSettings } from "./RobotViewSettings.service";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

export const RobotViewSettings = () => {
  const { t } = useTranslation();
  const [robotSettings, setRobotSettings] = useState<RobotAdminSettingsDto>({
    ...robotSettingsDefualt,
  });
  const [pin, setPin] = useState("");

  const [timeZones, setTimeZones] = useState<RobotTimeZone[]>([]);
  const [isWorking, setIsWorking] = useState(false);

  const { uiConfig, robotAdmin, refresh } = useContext(RobotViewContext);

  useEffect(() => {
    const fillTimeZones = async () => {
      try {
        setTimeZones(await getTimeZones());
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    fillTimeZones();
  }, []);

  useEffect(() => {
    setRobotSettings(
      robotAdmin?.settings ?? {
        ...robotSettingsDefualt,
      },
    );
    setPin(robotAdmin?.settings?.pin ?? "");
  }, [robotAdmin]);

  const updateSettingHandle = async (newSetting: RobotAdminSettingsDto) => {
    setIsWorking(true);

    try {
      await patchSettings(robotAdmin?.id ?? "", robotSettings, newSetting);
      setRobotSettings(newSetting);
      refresh();
      ToastHelper.success(t("adminPanel.notifications.success"));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  if (isWorking) {
    return (
      <div className="row settings-tab mt-5">
        <Loading size={120} />
      </div>
    );
  }

  const textColumns = 4;

  return (
    <div className="row settings-tab">
      <SubTitle text={t("adminPanel.robots.settings.robotSettings.title")} />
      <TextContainer
        text={t("adminPanel.robots.settings.robotSettings.lockScreenPIN")}
        className="align-self-center"
        columns={textColumns}
      >
        <div className="d-flex">
          <TextInput
            className="text-end my-0"
            value={pin}
            ret={(x) => setPin(x)}
          />

          <TooltipContainer
            text={t(
              "adminPanel.robots.settings.robotSettings.lockScreenPINSave",
            )}
          >
            <SaveOutlinedIcon
              className="icon-button pin-save"
              onClick={() =>
                updateSettingHandle({
                  ...robotSettings,
                  pin: pin,
                })
              }
            />
          </TooltipContainer>
        </div>
      </TextContainer>
      <TextContainer
        text={t("adminPanel.robots.settings.robotSettings.timezone")}
        className="align-self-center"
        columns={textColumns}
      >
        <NativeSelect
          className="w-100"
          value={robotSettings.timeZoneInfoId ?? " "}
          onChange={(event) =>
            updateSettingHandle({
              ...robotSettings,
              timeZoneInfoId: event.target.value,
            })
          }
        >
          {timeZones.map((x) => (
            <option key={x.id} value={x.id}>
              {x.displayName}
            </option>
          ))}
        </NativeSelect>
      </TextContainer>
      <TextContainer
        text={t("adminPanel.robots.settings.robotSettings.language")}
        columns={textColumns}
        className="align-self-center"
      >
        <NativeSelect
          className="w-100"
          value={robotSettings.language}
          onChange={(event) =>
            updateSettingHandle({
              ...robotSettings,
              language: event.target.value,
            })
          }
        >
          {Object.keys(resources).map((key) => (
            <option key={key} value={key}>
              {t(`language.${key}`)}
            </option>
          ))}
        </NativeSelect>
      </TextContainer>

      <SubTitle text={t("adminPanel.robots.settings.missionSettings.title")} />
      {uiConfig.enabledServerModeToggle && (
        <TextContainer
          text={t("adminPanel.robots.settings.missionSettings.serverMode")}
          columns={textColumns}
          className="align-self-center"
        >
          <SwitchInput
            checked={robotSettings.antServerModeEnabled}
            onCheckedChange={(x) =>
              updateSettingHandle({ ...robotSettings, antServerModeEnabled: x })
            }
          />
        </TextContainer>
      )}

      {!uiConfig.isDisabledControl && (
        <TextContainer
          text={t("adminPanel.robots.settings.missionSettings.dryMode")}
          columns={textColumns}
          className="align-self-center"
        >
          <SwitchInput
            checked={robotSettings.dryModeEnabled}
            onCheckedChange={(x) =>
              updateSettingHandle({ ...robotSettings, dryModeEnabled: x })
            }
          />
        </TextContainer>
      )}

      {!uiConfig.hideMissionEndBehavior && (
        <TextContainer
          text={t(
            "adminPanel.robots.settings.missionSettings.postMissionCharging",
          )}
          columns={textColumns}
          className="align-self-center"
        >
          <SwitchInput
            checked={
              robotSettings.missionEndBehavior === "chargeRobotAutomatic"
            }
            onCheckedChange={(x) =>
              updateSettingHandle({
                ...robotSettings,
                missionEndBehavior: x
                  ? "chargeRobotAutomatic"
                  : "readyForNextMission",
              })
            }
          />
        </TextContainer>
      )}

      <TextContainer
        text={t(
          "adminPanel.robots.settings.missionSettings.missionChargingStop.title",
        )}
        columns={textColumns}
        className="align-self-center"
      ></TextContainer>
      {!robotSettings.antServerModeEnabled && (
        <TextContainer
          text={t(
            "adminPanel.robots.settings.missionSettings.missionChargingStop.disable",
          )}
          columns={textColumns}
          className="align-self-center"
          isDoublePadding={true}
        >
          <Radio
            color="primary"
            checked={robotSettings.missionChargingBehavior === "disabled"}
            onChange={() =>
              updateSettingHandle({
                ...robotSettings,
                missionChargingBehavior: "disabled",
              })
            }
            value="disabled"
            name="disabled"
          />
        </TextContainer>
      )}

      <TextContainer
        text={t(
          "adminPanel.robots.settings.missionSettings.missionChargingStop.manual",
        )}
        columns={textColumns}
        className="align-self-center"
        isDoublePadding={true}
      >
        <Radio
          color="primary"
          checked={robotSettings.missionChargingBehavior === "manual"}
          onChange={() =>
            updateSettingHandle({
              ...robotSettings,
              missionChargingBehavior: "manual",
            })
          }
          value="manual"
          name="manual"
        />
      </TextContainer>

      {!uiConfig.hideMissionChargingBehaviorAutomatic && (
        <TextContainer
          text={t(
            "adminPanel.robots.settings.missionSettings.missionChargingStop.automatic",
          )}
          columns={textColumns}
          className="align-self-center"
          isDoublePadding={true}
        >
          <Radio
            color="primary"
            checked={robotSettings.missionChargingBehavior === "automatic"}
            onChange={() =>
              updateSettingHandle({
                ...robotSettings,
                missionChargingBehavior: "automatic",
              })
            }
            value="automatic"
            name="automatic"
          />
        </TextContainer>
      )}

      <TextContainer
        text={t("adminPanel.robots.settings.missionSettings.remoteSupport")}
        columns={textColumns}
        className="align-self-center"
      >
        <SwitchInput
          checked={robotSettings.remoteSupportEnabled}
          onCheckedChange={(x) =>
            updateSettingHandle({ ...robotSettings, remoteSupportEnabled: x })
          }
        />
      </TextContainer>

      <TextValue
        text={t("adminPanel.robots.settings.missionSettings.remoteSupportPort")}
        columns={textColumns}
        value={robotAdmin?.reverseSshPort}
      />
    </div>
  );
};

const robotSettingsDefualt: RobotAdminSettingsDto = {
  name: "",
  location: "",
  dryModeEnabled: false,
  remoteSupportEnabled: false,
  timeZoneInfoId: "",
  pin: "",
  missionChargingBehavior: "disabled",
  pairingMode: "disabled",
  language: "",
  missionEndBehavior: "readyForNextMission",
  license: "unlimited",
  antServerModeEnabled: false,
  advancedStartingParameters: "disabled",
  robotType: "ra660NaviXl",
};
