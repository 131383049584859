import { useTranslation } from "react-i18next";
import { Loading, TitleBar } from "../../../components";
import { useState, useEffect } from "react";
import { getExecutionInfo, postSynchronize } from "./AzureAdSync.service";
import { ExecutionInformationDto } from "../../../models";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { dateToHourDate } from "../../../tools";
import "./AzureAdSync.scss";

export const AzureAdSync = () => {
  const [isWorking, setIsWorking] = useState(false);
  const [data, setData] = useState({ ...defaultExecutionInformationDto });
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsWorking(true);

    try {
      setData(await getExecutionInfo());
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const synchronize = async () => {
    setIsWorking(true);

    try {
      await postSynchronize();
      await getData();
      ToastHelper.success(t("adminPanel.notifications.success"));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  return (
    <div className="row items-list-detail azure-ad-sync-container">
      <TitleBar
        title={t("adminPanel.organization.title")}
        canFilterButton={false}
      />
      {isWorking && (
        <div className="pt-5">
          <Loading />
        </div>
      )}

      {!isWorking && (
        <div className="row ps-4">
          <div className="col-12 mb-3">
            <h5 className="pt-4 fw-bold">
              {t("adminPanel.organization.azureAdSync.title")}
            </h5>
          </div>

          <div className="col-12 mb-3">
            <p className="d-flex">
              <span className="label">
                {t("adminPanel.organization.azureAdSync.lastSync")}
              </span>
              <span>{dateToHourDate(data.lastSync)}</span>
            </p>

            <p className="d-flex">
              <span className="label">
                {t("adminPanel.organization.azureAdSync.nextSync")}
              </span>
              <span>{dateToHourDate(data.nextSync)}</span>
            </p>
          </div>

          <div className="col-12 ps-4">
            <button
              className="btn btn-outline-cleanfix-no-border"
              onClick={() => synchronize()}
            >
              {t("adminPanel.organization.azureAdSync.syncNow")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const defaultExecutionInformationDto: ExecutionInformationDto = {
  lastSync: undefined,
  nextSync: new Date(),
};
