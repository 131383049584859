import { ReactElement } from "react";
import { Robots } from "../robots";
import i18n from "i18next";
import robotImg from "../../assets/Robot.png";
import BusinessIcon from "@mui/icons-material/Business";
import { AzureAdSync } from "../organization";
import SimCardOutlined from "@mui/icons-material/SimCardOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

import { SimCard } from "../sim/SimCard";
import { Dashboard } from "../dashboard/Dashboard";

export const MenuOptionsConfig: MenuOptionConfig[] = [
  {
    path: "/robots",
    text: i18n.t("adminPanel.robots.title"),
    image: robotImg,
    component: <Robots />,
  },
  {
    path: "/organization",
    text: i18n.t("adminPanel.organization.title"),
    icon: <BusinessIcon />,
    component: <AzureAdSync />,
  },
  {
    path: "/sim",
    text: i18n.t("adminPanel.sim.title"),
    icon: <SimCardOutlined />,
    component: <SimCard />,
  },
  {
    path: "/dashboard",
    text: i18n.t("adminPanel.dashboard.title"),
    icon: <AnalyticsOutlinedIcon />,
    component: <Dashboard />,
  },
];

export interface MenuOptionConfig {
  path?: string;
  image?: string;
  icon?: ReactElement;
  text: string;
  component?: ReactElement;
}
