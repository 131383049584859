import { diff, jsonPatchPathConverter } from "just-diff";
import { RobotAdminPostDto } from "../../models";
import { axiosPatch, axiosPost } from "../../services";

const url = "admin/v2/robots";

export const postRobot = async (robot: RobotAdminPostDto) => {
  await axiosPost(url, robot);
};

export const patchRobot = async (
  robotId: string,
  originalDto: RobotAdminPostDto,
  newDto: RobotAdminPostDto,
) => {
  const allowProperties = [
    "/name",
    "/location",
    "/tenant",
    "/robotType",
    "/license",
    "/routerSerialNumber",
    "/advancedStartingParameters",
  ];

  const jsonPatch = diff(originalDto, newDto, jsonPatchPathConverter).filter(
    (x) => allowProperties.includes(x.path),
  );

  await axiosPatch(`${url}/${robotId}`, jsonPatch);
};
