import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactElement } from "react";
import "./textBox.scss";

export const TextBox = (props: TextBoxProps) => {
  return (
    <div className="d-flex text-box">
      <div className={`d-flex ps-4 ${props.type} ${props.type}-border`}>
        {props.type === "warning" && (
          <ReportProblemOutlinedIcon className="align-self-center icon" />
        )}
        {props.type === "info" && (
          <InfoOutlinedIcon className="align-self-center icon" />
        )}
      </div>
      <div className={`flex-fill py-4 px-4 ${props.type}`}>
        {props.children}
      </div>
    </div>
  );
};

interface TextBoxProps {
  children?: ReactElement | never[];
  type: "warning" | "info" | "error";
}
