import { NativeSelect } from "@mui/material";
import { addToStringArray, removeToStringArray } from "../../tools";
import "./DropDownMultiselect.scss";

export const DropDownMultiselect = (props: DropDownMultiselectProps) => {
  return (
    <>
      <p className="mb-0 fw-bold">{props.label}</p>
      <NativeSelect
        fullWidth
        value="--"
        onChange={(event) =>
          props.selectionChange(
            addToStringArray(props.itemsSelected, event.target.value),
          )
        }
      >
        <option value="--">{props.selectItemText}</option>

        {props.itemsToShow.map((x) => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </NativeSelect>
      {props.itemsSelected && props.itemsSelected.length > 0 && (
        <div className="badge-container mt-2">
          {props.itemsSelected.map((x) => (
            <span
              key={x}
              className="badge rounded-pill "
              onClick={() =>
                props.selectionChange(
                  removeToStringArray(props.itemsSelected, x),
                )
              }
            >
              {x}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

interface DropDownMultiselectProps {
  label: string;
  selectItemText: string;
  itemsToShow: string[];
  itemsSelected: string[] | undefined;
  selectionChange: (selectiion: string[]) => void;
}
