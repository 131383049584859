import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { SubTitle } from "./common";
import { RobotAdminDto, SimSubscriptionDataDto } from "../../../models";
import { Loading, ModalWindow, TextInput } from "../../../components";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { SimDataDto } from "../../../models";

import {
  getSimData,
  getSimSubscriptions,
  putSimAlias,
  putSimSubscription,
} from "./RobotViewSim.service";
import "./RobotViewSim.scss";
import { RobotViewSimSubscription } from "./RobotViewSimSubscription";
import { RobotViewSimData } from "./RobotViewSimData";
import { NativeSelect, Switch } from "@mui/material";
import { RobotViewSimRemoveModal } from "./RobotViewSimRemoveModal";
import { RobotViewSimAssignModal } from "./RobotViewSimAssignModal";

export const RobotViewSimAssigned = ({
  robotAdmin,
  refresh,
}: RobotViewSimProp) => {
  const { t } = useTranslation();
  const [isWorking, setIsWorking] = useState(true);
  const [isAutoRenew, setIsAutoRenew] = useState(true);
  const [isSimUnregistered, setIsSimUnregistered] = useState(false);
  const [isSimServerError, setIsSimServerError] = useState(false);
  const [simData, setSimData] = useState<SimDataDto | undefined>(undefined);
  const [alias, setAlias] = useState("");
  const [subscriptions, setSubscriptions] = useState<SimSubscriptionDataDto[]>(
    [],
  );
  const [selectedSubscription, setSelectedSubscription] = useState("0");

  const [confirmAction, setConfirmAction] = useState<
    "AddNewSim" | "EditSubscription" | "EditAlias" | "RemoveSim" | undefined
  >(undefined);

  useEffect(() => {
    const getSim = async () => {
      try {
        const a = await getSimData(robotAdmin.iccid ?? "");
        setSimData(a);
        setAlias(a.simAlias ?? "");
        setIsSimUnregistered(false);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e: any = error;
        if (e.response) {
          if (e.response.status === 404) {
            setIsSimUnregistered(true);
          } else {
            setIsSimServerError(true);
          }
        } else {
          ToastHelper.errors(ErrorHelper.process(error));
        }
      }
    };
    setIsWorking(true);
    getSim();
    setIsWorking(false);
  }, [robotAdmin.iccid]);

  const getSubscriptions = async () => {
    setIsWorking(true);

    try {
      const a = await getSimSubscriptions(robotAdmin.iccid ?? "");
      setSubscriptions(a);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const editAlias = async () => {
    setIsWorking(true);

    try {
      await putSimAlias(robotAdmin.iccid ?? "", alias);
      ToastHelper.success(t("adminPanel.notifications.success"));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const editSubscription = async () => {
    setIsWorking(true);

    try {
      await putSimSubscription(
        robotAdmin.iccid ?? "",
        +selectedSubscription === -1
          ? -1
          : subscriptions[+selectedSubscription].productId ?? 0,
        isAutoRenew,
      );
      ToastHelper.success(t("adminPanel.notifications.success"));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const editAliasModal = (
    <ModalWindow
      size={"xs"}
      onClose={() => setConfirmAction(undefined)}
      title={t("adminPanel.robots.sim.editAlias.title")}
      footer={
        <>
          <button
            className="btn btn-cleanfix text-uppercase me-2"
            onClick={() => {
              setAlias(simData?.simAlias ?? "");
              setConfirmAction(undefined);
            }}
          >
            {t("common.cancel")}
          </button>

          <button
            style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
            disabled={typeof selectedSubscription === "undefined"}
            className="btn btn-cleanfix text-uppercase "
            onClick={() => {
              editAlias();
              setConfirmAction(undefined);
              refresh();
            }}
          >
            {t("adminPanel.robots.sim.simActions.editAlias")}
          </button>
        </>
      }
    >
      <>
        <div className="row mb-5">
          <div className="col-2 modal-label">
            {t("adminPanel.robots.sim.common.alias")}
          </div>
          <div className="col-9">
            <TextInput
              className="text-start my-0"
              value={alias}
              ret={(x) => {
                setAlias(x);
              }}
            />
          </div>
          <div className="col-auto"></div>
        </div>
      </>
    </ModalWindow>
  );

  const editSubscriptionModal = (
    <ModalWindow
      size={"xs"}
      onClose={() => setConfirmAction(undefined)}
      title={t("adminPanel.robots.sim.editSubscription.title")}
      footer={
        <>
          <button
            className="btn btn-cleanfix text-uppercase me-2"
            onClick={() => {
              setSelectedSubscription("0");
              setConfirmAction(undefined);
            }}
          >
            {t("common.cancel")}
          </button>

          <button
            style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
            disabled={typeof selectedSubscription === "undefined"}
            className="btn btn-cleanfix text-uppercase "
            onClick={() => {
              editSubscription();
              setConfirmAction(undefined);
              refresh();
            }}
          >
            {selectedSubscription === "-1"
              ? t("adminPanel.robots.sim.simActions.removeSubscription")
              : t("adminPanel.robots.sim.simActions.editSubscription")}
          </button>
        </>
      }
    >
      <>
        <div className="row mb-4">
          <div
            className="col-3 pt-2 pe-0 me-4"
            style={{ maxWidth: "max-content" }}
          >
            {t("adminPanel.robots.sim.editSubscription.subscription")}
          </div>
          <div className="col ps-0 ms-0">
            <NativeSelect
              className="w-100"
              value={selectedSubscription}
              onChange={(event) => {
                setSelectedSubscription(event.target.value);
              }}
            >
              <option key={"-1"} value={-1}>
                {t("adminPanel.robots.sim.editSubscription.removeSubscription")}
              </option>
              {subscriptions.map((x, index) => (
                <option key={`${index}`} value={index}>
                  {x.shortName}
                </option>
              ))}
            </NativeSelect>
          </div>
          <div className="col-auto"></div>
        </div>
        <div className="row mb-3">
          <div className="col-3 pt-2">
            {t("adminPanel.robots.sim.common.autorenew")}
          </div>
          <Switch
            checked={isAutoRenew}
            onChange={() => setIsAutoRenew((prev) => !prev)}
            inputProps={{ "aria-label": "controlled-switch" }}
            name="autorenew"
            size="medium"
          />
        </div>
      </>
    </ModalWindow>
  );

  const unregisteredSim = (
    <>
      <div className="row mt-2">
        <div className="col-12">
          {t("adminPanel.robots.sim.common.unregisteredCard", {
            iccid: robotAdmin.iccid,
            cleanfixSerialNumber: robotAdmin.cleanfixSerialNumber,
          })}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col"></div>
        <button
          style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
          className="btn btn-outline-cleanfix-no-border col-4"
          onClick={() => setConfirmAction("RemoveSim")}
        >
          {t("adminPanel.robots.sim.simActions.removeSim")}
        </button>
      </div>
      {confirmAction === "RemoveSim" && (
        <RobotViewSimRemoveModal
          robotId={robotAdmin.id}
          iccid={robotAdmin.iccid ?? ""}
          cleanfixSerialNumber={robotAdmin.cleanfixSerialNumber}
          onClose={() => {
            setConfirmAction(undefined);
          }}
          removed={refresh}
        />
      )}
    </>
  );

  const simServerError = (
    <>
      <div className="row mt-2">
        <div className="col-12">
          {t("adminPanel.robots.sim.common.simServerError")}
        </div>
      </div>
    </>
  );

  return (
    <>
      {isWorking && (
        <div className="row py-5">
          <Loading size={100} />
        </div>
      )}
      {!isWorking && isSimServerError && simServerError}
      {!isWorking && isSimUnregistered && unregisteredSim}
      {!isWorking && simData !== undefined && (
        <>
          <RobotViewSimData simData={simData} />
          <SubTitle text={t("adminPanel.robots.sim.common.currentServices")} />
          <div
            className={
              simData.currentServices.length ? "accordion-container" : ""
            }
          >
            {simData.currentServices.map((service, index) => (
              <div key={`${index}`}>
                {!!index && (
                  <div style={{ borderTop: "1px solid silver" }}></div>
                )}
                <RobotViewSimSubscription service={service} />
              </div>
            ))}
          </div>
          <SubTitle text={t("adminPanel.robots.sim.common.subscriptions")} />
          <div
            className={
              simData.subscriptions.length ? "accordion-container" : ""
            }
          >
            {simData.subscriptions.map((service, index) => (
              <div key={`${index}`}>
                {!!index && (
                  <div style={{ borderTop: "1px solid silver" }}></div>
                )}

                <RobotViewSimSubscription service={service} />
              </div>
            ))}
          </div>
          <div className="row mt-4">
            <div className="col"></div>
            <button
              style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
              className="btn btn-outline-cleanfix-no-border col-4"
              onClick={() => setConfirmAction("RemoveSim")}
            >
              {t("adminPanel.robots.sim.simActions.removeSim")}
            </button>

            <button
              className="btn btn-outline-cleanfix-no-border col-4"
              onClick={() => setConfirmAction("AddNewSim")}
            >
              {t("adminPanel.robots.sim.simActions.addNewSim")}
            </button>
          </div>
          <div className="row mt-3">
            <div className="col"></div>
            <button
              style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
              className="btn btn-outline-cleanfix-no-border col-4"
              onClick={() => {
                getSubscriptions();
                setConfirmAction("EditSubscription");
              }}
            >
              {t("adminPanel.robots.sim.simActions.editSubscription")}
            </button>

            <button
              style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
              className="btn btn-outline-cleanfix-no-border col-4"
              onClick={() => setConfirmAction("EditAlias")}
            >
              {t("adminPanel.robots.sim.simActions.editAlias")}
            </button>
          </div>
          {confirmAction === "EditSubscription" && editSubscriptionModal}
          {confirmAction === "EditAlias" && editAliasModal}
          {confirmAction === "RemoveSim" && (
            <RobotViewSimRemoveModal
              robotId={robotAdmin.id}
              iccid={robotAdmin.iccid ?? ""}
              cleanfixSerialNumber={robotAdmin.cleanfixSerialNumber}
              onClose={() => {
                setConfirmAction(undefined);
              }}
              removed={refresh}
            />
          )}
          {confirmAction === "AddNewSim" && (
            <RobotViewSimAssignModal
              robotId={robotAdmin.id}
              cleanfixSerialNumber={robotAdmin.cleanfixSerialNumber}
              routerSerialNumber={robotAdmin.routerSerialNumber}
              onClose={() => {
                setConfirmAction(undefined);
              }}
              assigned={refresh}
            />
          )}
        </>
      )}
    </>
  );
};

interface RobotViewSimProp {
  robotAdmin: RobotAdminDto;
  refresh: () => Promise<void>;
}
