import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading, ModalWindow } from "../../../components";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { deleteRobot } from "./RobotDeleteModal.service";

export const RobotDeleteModal = (props: RobotDeleteModalProps) => {
  const [isWorking, setIsWorking] = useState(false);

  const { t } = useTranslation();

  const deleteHandel = async () => {
    setIsWorking(true);

    try {
      await deleteRobot(props.robotId);
      ToastHelper.success(t("adminPanel.notifications.success"));

      props.deleted();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  return (
    <ModalWindow
      size={"sm"}
      onClose={props.onClose}
      title={t("adminPanel.robots.deleteModal.title")}
      footer={
        <>
          {!isWorking && (
            <>
              <button
                className="btn btn-cleanfix text-uppercase me-2"
                onClick={deleteHandel}
              >
                {t("common.yes")}
              </button>

              <button
                className="btn btn-cleanfix text-uppercase "
                onClick={props.onClose}
              >
                {t("common.no")}
              </button>
            </>
          )}
        </>
      }
    >
      <>
        {isWorking && (
          <div className="row py-5">
            <Loading size={100} />
          </div>
        )}

        {!isWorking && (
          <div className="row">
            <div className="col-12 p-5 mb-3">
              {t("adminPanel.robots.deleteModal.text", {
                cleanfixSerialNumber: props.cleanfixSerialNumber,
              })}
            </div>
          </div>
        )}
      </>
    </ModalWindow>
  );
};

interface RobotDeleteModalProps {
  robotId: string;
  cleanfixSerialNumber: string;
  onClose: () => void;
  deleted: () => void;
}
