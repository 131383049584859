import { useEffect, useState } from "react";
import {
  Loading,
  ModalWindow,
  NumericInput,
  TextBox,
  TextInput,
} from "../../components";
import { useTranslation } from "react-i18next";
import { TextContainer } from "./robotView/common";
import {
  AdvancedStartingParameters,
  RobotAdminDto,
  RobotAdminPostDto,
  RobotDefaultConfigurationDto,
  RobotLicense,
  RobotType,
} from "../../models";
import { NativeSelect } from "@mui/material";
import { ErrorHelper, ToastHelper } from "../../helpers";
import { patchRobot, postRobot } from "./RobotModal.service";
import { stringIsNullOrWhiteSpace } from "../../tools/string";

export const RobotModal = (props: RobotModalProps) => {
  const [dtoOriginal, setDtoOriginal] = useState<RobotAdminPostDto | undefined>(
    undefined,
  );
  const [dto, setDto] = useState({ ...robotAdminPostDto });
  const [isWorking, setIsWorking] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [licenseAllowed, setLicenseAllowed] = useState([...robotLicenses]);
  const [robotType, setRobotType] = useState<RobotType | null>(null);
  const [robotLicense, setRobotLicense] = useState<RobotLicense | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (!props.robotAdmin) {
      if (props.robotDefaultConfiguration) {
        setDto({
          ...robotAdminPostDto,
          tenant: props.robotDefaultConfiguration.tenant,
          location: props.robotDefaultConfiguration.location,
          advancedStartingParameters:
            props.robotDefaultConfiguration.advanceStartingParameters,
        });
      }

      setRobotType("ra660NaviXl");
      setRobotLicense("unlimitedPro");
      return;
    }

    setIsEdit(true);
    setRobotType(props.robotAdmin.settings.robotType);
    setRobotLicense(props.robotAdmin.settings.license);

    const tmp = {
      cleanfixSerialNumber: props.robotAdmin.cleanfixSerialNumber,
      name: props.robotAdmin.settings.name,
      location: props.robotAdmin.settings.location,
      tenant: props.robotAdmin.tenant,
      robotType: props.robotAdmin.settings.robotType ?? "ra660NaviM",
      license: props.robotAdmin.settings.license ?? "pure",
      advancedStartingParameters:
        props.robotAdmin.settings.advancedStartingParameters,
      reverseSSHPort: props.robotAdmin.reverseSshPort,
      routerSerialNumber: props.robotAdmin.routerSerialNumber,
    };

    setDto({ ...tmp });
    setDtoOriginal({ ...tmp });

    if (tmp.robotType === "ra660NaviM") setLicenseAllowed([...robotMLicenses]);
  }, [props.robotAdmin, props.robotDefaultConfiguration]);

  useEffect(() => {
    setIsSaveDisabled(
      (!isEdit && stringIsNullOrWhiteSpace(dto.cleanfixSerialNumber)) ||
        stringIsNullOrWhiteSpace(dto.name) ||
        stringIsNullOrWhiteSpace(dto.location) ||
        stringIsNullOrWhiteSpace(dto.tenant) ||
        !!!robotType ||
        !!!robotLicense ||
        (!isEdit && (!!!dto.reverseSSHPort || dto.reverseSSHPort === 0)),
    );
  }, [dto, isEdit, robotType, robotLicense]);

  const createRobot = async () => {
    setIsWorking(true);

    if (!robotType || !robotLicense) return;

    const data: RobotAdminPostDto = {
      ...dto,
      robotType: robotType,
      license: robotLicense,
    };

    try {
      await postRobot(data);
      ToastHelper.success(t("adminPanel.notifications.success"));
      props.onSave();
      props.onClose();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const editRobot = async () => {
    if (!props.robotAdmin || !dtoOriginal || !robotType || !robotLicense)
      return;

    const modified: RobotAdminPostDto = {
      ...dto,
      robotType: robotType,
      license: robotLicense,
    };

    const original: RobotAdminPostDto = {
      ...dtoOriginal,
    };

    if (
      props.robotAdmin.settings.robotType === null &&
      original.robotType === modified.robotType
    )
      original.robotType =
        original.robotType === "ra660NaviM" ? "ra660NaviXl" : "ra660NaviM";

    if (
      props.robotAdmin.settings.license === null &&
      original.license === modified.license
    )
      original.license = original.license === "pure" ? "connect" : "pure";

    setIsWorking(true);

    try {
      await patchRobot(props.robotAdmin.id, original, modified);
      props.onSave(props.robotAdmin.id);
      props.onClose();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const robotTypeChange = (robotType: RobotType) => {
    let newLicense: RobotLicense;

    if (robotType === "ra660NaviM") {
      setLicenseAllowed([...robotMLicenses]);
      newLicense = "connect";
    } else {
      setLicenseAllowed([...robotLicenses]);
      newLicense = "unlimitedPro";
    }

    setRobotLicense(newLicense);
    setRobotType(robotType);

    setDto({
      ...dto,
      license: "unlimitedPro",
      robotType: robotType,
    });
  };

  const cleanfixSerialNumberChange = (text: string) => {
    if ((dto.name?.length ?? 0) === 0 || dto.name === dto.cleanfixSerialNumber)
      setDto({
        ...dto,
        cleanfixSerialNumber: text,
        name: text,
      });
    else
      setDto({
        ...dto,
        cleanfixSerialNumber: text,
      });
  };

  return (
    <ModalWindow
      size={"sm"}
      onClose={props.onClose}
      title={
        isEdit
          ? t("adminPanel.robots.robotModal.editTitle")
          : t("adminPanel.robots.robotModal.createTitle")
      }
      footer={
        <>
          {!isWorking && (
            <>
              {!isEdit && (
                <button
                  className="btn btn-cleanfix text-uppercase me-2"
                  onClick={createRobot}
                  disabled={isSaveDisabled}
                >
                  {t("adminPanel.robots.robotModal.create")}
                </button>
              )}

              {isEdit && (
                <button
                  className="btn btn-cleanfix text-uppercase me-2"
                  onClick={editRobot}
                  disabled={isSaveDisabled}
                >
                  {t("adminPanel.robots.robotModal.edit")}
                </button>
              )}

              <button
                className="btn btn-cleanfix text-uppercase "
                onClick={props.onClose}
              >
                {t("common.cancel")}
              </button>
            </>
          )}
        </>
      }
    >
      <>
        {isWorking && (
          <div className="row py-5">
            <Loading size={100} />
          </div>
        )}
        {!isWorking && (
          <div className="row">
            {!isEdit && (
              <TextContainer
                text={t("adminPanel.robots.robotModal.cleanfixSerialNumber")}
                className="align-self-center"
                showMandatory={stringIsNullOrWhiteSpace(
                  dto.cleanfixSerialNumber,
                )}
              >
                <TextInput
                  className="text-end my-0"
                  value={dto.cleanfixSerialNumber}
                  ret={cleanfixSerialNumberChange}
                />
              </TextContainer>
            )}

            <TextContainer
              text={t("adminPanel.robots.robotModal.name")}
              className="align-self-center"
              showMandatory={stringIsNullOrWhiteSpace(dto.name)}
            >
              <TextInput
                className="text-end my-0"
                value={dto.name}
                ret={(x) => setDto({ ...dto, name: x })}
              />
            </TextContainer>

            <TextContainer
              text={t("adminPanel.robots.robotModal.location")}
              className="align-self-center"
              showMandatory={stringIsNullOrWhiteSpace(dto.location)}
            >
              <TextInput
                className="text-end my-0"
                value={dto.location}
                ret={(x) => setDto({ ...dto, location: x })}
              />
            </TextContainer>

            <TextContainer
              text={t("adminPanel.robots.robotModal.routerSerialNumber")}
              className="align-self-center"
            >
              <TextInput
                className="text-end my-0"
                value={dto.routerSerialNumber}
                ret={(x) => setDto({ ...dto, routerSerialNumber: x })}
              />
            </TextContainer>

            <TextContainer
              text={t("adminPanel.robots.robotModal.tenant")}
              className="align-self-center"
              showMandatory={stringIsNullOrWhiteSpace(dto.tenant)}
            >
              <TextInput
                className="text-end my-0"
                value={dto.tenant}
                ret={(x) => setDto({ ...dto, tenant: x })}
              />
            </TextContainer>

            <TextContainer
              text={t("adminPanel.robots.robotModal.robotType")}
              className="align-self-center"
              showMandatory={!!!robotType}
            >
              <NativeSelect
                className="w-100"
                value={robotType ?? " "}
                onChange={(event) =>
                  robotTypeChange(event.target.value as RobotType)
                }
              >
                {!robotType && <option value={" "}>&nbsp;</option>}
                {robotTypes.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </NativeSelect>
            </TextContainer>

            <TextContainer
              text={t("adminPanel.robots.robotModal.license")}
              className="align-self-center"
              showMandatory={!!!robotLicense}
            >
              <NativeSelect
                className="w-100"
                value={robotLicense ?? " "}
                onChange={(event) =>
                  setRobotLicense(event.target.value as RobotLicense)
                }
              >
                {!robotLicense && <option value={" "}>&nbsp;</option>}
                {licenseAllowed.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </NativeSelect>
            </TextContainer>

            <TextContainer
              text={t(
                "adminPanel.robots.robotModal.advancedStartingParameters",
              )}
              className="align-self-center"
            >
              <NativeSelect
                className="w-100"
                value={dto.advancedStartingParameters}
                onChange={(event) =>
                  setDto({
                    ...dto,
                    advancedStartingParameters: event.target
                      .value as AdvancedStartingParameters,
                  })
                }
              >
                {advancedStartingParameters.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </NativeSelect>
            </TextContainer>

            {!isEdit && (
              <TextContainer
                text={t("adminPanel.robots.robotModal.remoteSupportPort")}
                className="align-self-center"
                showMandatory={!dto.reverseSSHPort || dto.reverseSSHPort === 0}
              >
                <NumericInput
                  className="text-end my-0"
                  value={dto.reverseSSHPort ?? 0}
                  ret={(x) => setDto({ ...dto, reverseSSHPort: x })}
                />
              </TextContainer>
            )}

            {isSaveDisabled && (
              <div className="col-12 mb-3">
                <TextBox type="error">
                  <>
                    <span className="mandatory ms-2">*</span>{" "}
                    {t("adminPanel.selectionMandatory")}
                  </>
                </TextBox>
              </div>
            )}
          </div>
        )}
      </>
    </ModalWindow>
  );
};

interface RobotModalProps {
  robotAdmin?: RobotAdminDto | undefined;
  onSave: (id?: string) => void;
  onClose: () => void;
  robotDefaultConfiguration: RobotDefaultConfigurationDto | undefined;
}

const robotAdminPostDto: RobotAdminPostDto = {
  cleanfixSerialNumber: "",
  name: "",
  location: "",
  tenant: "",
  robotType: "ra660NaviXl",
  license: "unlimitedPro",
  advancedStartingParameters: "disabled",
  reverseSSHPort: undefined,
  routerSerialNumber: "",
};

const robotTypes: RobotType[] = ["ra660NaviM", "ra660NaviXl"];
const robotLicenses: RobotLicense[] = [
  "unlimited",
  "pure",
  "connect",
  "unlimitedPro",
  "server",
];
const robotMLicenses: RobotLicense[] = ["pure", "connect"];
const advancedStartingParameters: AdvancedStartingParameters[] = [
  "unsupported",
  "disabled",
  "enabled",
];
