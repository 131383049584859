import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FilterBar.scss";
import { filterBarSubscriptionManager } from "./FilterBarSubscriptionManager";

export const FilterBar = (props: FilterBarProps) => {
  const [textToSearch, setTextToSearch] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const filterBarSubscription = filterBarSubscriptionManager
      .getObservable()
      .subscribe((x) => rsjxChange(x));

    return () => {
      filterBarSubscription.unsubscribe();
    };
  });

  const rsjxChange = (newText: string) => {
    setTextToSearch(newText);
    props.search(newText);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextToSearch(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") return;
    props.search(textToSearch);
  };

  const handleClear = () => {
    setTextToSearch("");
    props.search("");
  };

  return (
    <div className="row filter-bar">
      <div className="col px-0 search-container">
        <SearchIcon className="search-icon" />
      </div>
      <div className="col px-0">
        <input
          value={textToSearch}
          className="input w-100"
          placeholder={`${t("adminPanel.filterBar.search")}...`}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
      </div>
      <div className="col px-0 clear-container">
        {textToSearch && (
          <CloseIcon
            className="clear-icon cursor-pointer"
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );
};

interface FilterBarProps {
  search: (textToSearch: string) => void;
}
