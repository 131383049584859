import cleanfixLogo from "../../../assets/cleanfix-logo.png";

export const Footer = () => {
  return (
    <div className="col-12 text-center footer pt-3">
      <img src={cleanfixLogo} className="logo-brand" alt="Cleanfix" />
      <p className="mb-0 mt-3">RA660 Navi Admin Panel</p>
      <p className="mb-0">Made with ❤️ by Earlybyte</p>
      <p className="mb-0">{process.env.REACT_APP_UI_VERSION}</p>
    </div>
  );
};
