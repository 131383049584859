import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { RobotViewContext } from "./RobotViewContext";
import { SubTitle } from "./common";
import "./RobotViewSim.scss";
import { RobotViewSimAssigned } from "./RobotViewSimAssigned";
import { RobotViewSimNoAssigned } from "./RobotViewSimNotAssigned";

export const RobotViewSim = () => {
  const { t } = useTranslation();

  const { robotAdmin, refresh } = useContext(RobotViewContext);

  if (!robotAdmin) return <></>;

  return (
    <div className="row system-tab">
      <SubTitle text={t("adminPanel.robots.sim.title")} />

      {robotAdmin.iccid === null ? (
        <RobotViewSimNoAssigned robotAdmin={robotAdmin} refresh={refresh} />
      ) : (
        <RobotViewSimAssigned robotAdmin={robotAdmin} refresh={refresh} />
      )}
    </div>
  );
};
