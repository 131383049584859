import { PuffLoader } from "react-spinners";
import "./Loading.scss";

export const Loading = (props: LoadingProps) => {
  return (
    <div className="loading-container">
      <PuffLoader color={"#0a67aa"} size={props.size ?? 100} />
    </div>
  );
};

interface LoadingProps {
  size?: number;
}
