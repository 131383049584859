import { Routes, Route, Navigate } from "react-router-dom";
import { MenuOptionsConfig } from "../MenuOptions";

export const RightSide = () => {
  return (
    <div className="flex-fill right-side-panel">
      <div className="w-100 options-container">
        <Routes>
          {MenuOptionsConfig.map((x) => (
            <Route key={x.path} path={x.path} element={x.component} />
          ))}
          <Route path="*" element={<Navigate to="/robots" />} />
        </Routes>
      </div>
    </div>
  );
};
