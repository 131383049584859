import { ReactElement } from "react";
import { Tooltip } from "@mui/material";

export const TooltipContainer = (props: tooltipContainerProps) => {
  return (
    <Tooltip
      title={
        props.content ? (
          props.content
        ) : (
          <span className={props.className}>{props.text}</span>
        )
      }
      placement={props.placement}
      enterDelay={props.enterDelay}
      leaveDelay={props.leaveDelay}
    >
      <div
        style={{
          display: "inline",
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};

interface tooltipContainerProps {
  text?: string | null | undefined;
  content?: ReactElement;
  children: ReactElement;
  className?: string;
  placement?: toolTipPlacement;
  enterDelay?: number;
  leaveDelay?: number;
}

TooltipContainer.defaultProps = {
  placement: "top-end",
  enterDelay: 1000,
  leaveDelay: 200,
  className: "tool-tip-text",
};

export type toolTipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";
