import { ReactElement } from "react";

export const TextContainer = (props: TextContainerProps) => (
  <>
    <div
      className={`col-${props?.columns ?? 6} ${
        props.className ?? ""
      } ps-5 mb-3`}
    >
      <span className={props.isDoublePadding ? "ps-5" : ""}>{props.text}</span>
      {props.showMandatory && <span className="mandatory ms-2">*</span>}
    </div>
    <div className={`col-${12 - (props?.columns ?? 6)} text-end ps-5 mb-3`}>
      {props.children}
    </div>
  </>
);

interface TextContainerProps {
  text: string;
  children?: ReactElement | never[];
  isDoublePadding?: boolean | undefined;
  columns?: number;
  className?: string;
  showMandatory?: boolean;
}
