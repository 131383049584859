import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading, ModalWindow } from "../../components";
import { ErrorHelper, ToastHelper } from "../../helpers";
import { dateToHourDate } from "../../tools";

export const DashboardRefreshModal = (props: DashboardRefreshModalProps) => {
  const [isWorking, setIsWorking] = useState(false);

  const { t } = useTranslation();

  const refreshHandle = async () => {
    setIsWorking(true);

    try {
      props.refreshed();
      props.onClose();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  return (
    <ModalWindow
      size={"sm"}
      onClose={props.onClose}
      title={"Refresh Dashboard"}
      footer={
        <>
          {!isWorking && (
            <>
              <button
                className="btn btn-cleanfix text-uppercase me-2"
                onClick={refreshHandle}
              >
                {t("common.yes")}
              </button>

              <button
                className="btn btn-cleanfix text-uppercase "
                onClick={props.onClose}
              >
                {t("common.no")}
              </button>
            </>
          )}
        </>
      }
    >
      <>
        {isWorking && (
          <div className="row py-5">
            <Loading size={100} />
          </div>
        )}

        {!isWorking && (
          <div className="row">
            <div className="col-12 p-5 mb-3">
              Do you really want to refresh the dashboard data? This action will
              require a significant amount of resources. <br />
              <br />
              Latest Mission Data Refresh:{" "}
              {dateToHourDate(props.missionsStatisticsCreatedAt)} <br />
              Latest Fleet Data Refresh:{" "}
              {dateToHourDate(props.fleetStatisticsCreatedAt)} <br />
              Latest Robot List Refresh:{" "}
              {dateToHourDate(props.robotsStatisticsCreatedAt)} <br />
            </div>
          </div>
        )}
      </>
    </ModalWindow>
  );
};

interface DashboardRefreshModalProps {
  onClose: () => void;
  refreshed: () => void;
  fleetStatisticsCreatedAt: Date | undefined;
  missionsStatisticsCreatedAt: Date | undefined;
  robotsStatisticsCreatedAt: Date | undefined;
}
