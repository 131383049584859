import { ErrorData } from "../models";

export class ErrorHelper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static process = (error: any) => {
    if (error.response) {
      if (error.response.data.validations) {
        return error.response.data.validations as ErrorData[];
      }

      if (error.response.data) {
        const result: ErrorData[] = [];

        if (error.response.data.status && error.response.data.status === 401) {
          result.push({
            property: "",
            message: "Unauthorized",
          });
        } else if (error.response.data.innerMessages as string[]) {
          (error.response.data.innerMessages as string[]).map((x) =>
            result.push({
              property: "",
              message: `${x}`,
            }),
          );
        } else if (error.response.data.error) {
          result.push({
            property: "",
            message: error.response.data.error.message,
          });
        } else if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((prop) => {
            if (error.response.data.errors[prop] as string[]) {
              (error.response.data.errors[prop] as string[]).map((x) =>
                result.push({
                  property: prop,
                  message: `${x}`,
                }),
              );
            }
          });
        } else if (error.response.data.debugMessage) {
          result.push({
            property: "",
            message: error.response.data.debugMessage,
          });
        } else {
          result.push({
            property: "",
            message: `${error.response.data}`,
          });
        }

        return result;
      }

      if (error.response.statusText) {
        const result: ErrorData[] = [
          {
            property: "",
            message: `${error.response.statusText}`,
          },
        ];

        return result;
      }

      const result: ErrorData[] = [
        {
          property: "",
          message: `${error.response}`,
        },
      ];

      return result;
    }

    if (error.message) {
      const result: ErrorData[] = [
        {
          property: "",
          message: `${error.message}`,
        },
      ];

      return result;
    }

    console.log("ERROR UNKNOWN");
    const result: ErrorData[] = [
      {
        property: "",
        message: "ERROR UNKNOWN",
      },
    ];

    return result;
  };
}
