import { useTranslation } from "react-i18next";
import {
  Loading,
  ModalWindow,
  SwitchInput,
  TextInput,
} from "../../../components";
import { useContext, useState } from "react";
import { ChargingAssistantConfigPostDto } from "../../../models";
import { TextContainer } from "./common";
import { RobotViewContext } from "./RobotViewContext";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { putChargingAssistant } from "./RobotViewChargingModal.service";

export const RobotViewChargingModal = (props: RobotViewChargingModalProps) => {
  const [dto, setDto] = useState(chargingAssistantConfigPostDtoDefault);
  const [valveWifi, setValveWifi] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const { t } = useTranslation();
  const { robotAdmin } = useContext(RobotViewContext);

  const apply = async () => {
    setIsWorking(true);

    try {
      await putChargingAssistant(robotAdmin?.id ?? "", {
        valveBleAddress: valveWifi ? undefined : dto.valveBleAddress,
        kukaSsid: dto.kukaSsid ?? "",
      });
      ToastHelper.success(t("adminPanel.notifications.success"));

      props.onClose();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  return (
    <ModalWindow
      size={"sm"}
      onClose={props.onClose}
      title={t("adminPanel.robots.charging.modal.title")}
      footer={
        <>
          {!isWorking && (
            <>
              <button
                className="btn btn-cleanfix text-uppercase me-2"
                onClick={apply}
              >
                {t("adminPanel.robots.charging.pair")}
              </button>

              <button
                className="btn btn-cleanfix text-uppercase "
                onClick={props.onClose}
              >
                {t("common.cancel")}
              </button>
            </>
          )}
        </>
      }
    >
      <>
        {isWorking && (
          <div className="row py-5">
            <Loading size={100} />
          </div>
        )}
        {!isWorking && (
          <div className="row">
            <TextContainer
              text={t("adminPanel.robots.charging.modal.ssid")}
              className="align-self-center"
            >
              <TextInput
                className="text-end my-0"
                value={dto.kukaSsid}
                ret={(x) => setDto({ ...dto, kukaSsid: x })}
              />
            </TextContainer>
            <TextContainer
              text={t("adminPanel.robots.charging.modal.wifiMode")}
              className="align-self-center"
            >
              <SwitchInput
                checked={valveWifi}
                onCheckedChange={(x) => setValveWifi(x)}
              />
            </TextContainer>

            {!valveWifi && (
              <TextContainer
                text={t("adminPanel.robots.charging.modal.bleAddress")}
                className="align-self-center"
              >
                <TextInput
                  className="text-end my-0"
                  value={dto.valveBleAddress ?? ""}
                  ret={(x) => setDto({ ...dto, valveBleAddress: x })}
                />
              </TextContainer>
            )}
          </div>
        )}
      </>
    </ModalWindow>
  );
};

interface RobotViewChargingModalProps {
  onClose: () => void;
}

const chargingAssistantConfigPostDtoDefault: ChargingAssistantConfigPostDto = {
  valveBleAddress: undefined,
  kukaSsid: "",
};
