import { CleaningLiftDevice } from "../../../models/resource-models";
import { RobotCucItemStatus } from "./RobotCucItemStatus";
import { useTranslation } from "react-i18next";

interface RobotCucCleaningLiftDeviceStatusProp {
  label: string;
  item?: CleaningLiftDevice;
}
export const RobotCucCleaningLiftDeviceStatus = ({
  label,
  item,
}: RobotCucCleaningLiftDeviceStatusProp) => {
  const { t } = useTranslation();
  return (
    <RobotCucItemStatus
      healthy={item?.healthy ?? false}
      label={label}
      state={t(
        `check.cuc.enums.${item?.state ?? "NoAvailableData"}`,
      )?.toString()}
      errorEnum={item?.error.toString()}
      remark={
        item
          ? `${t(`check.cuc.enums.${item?.action.toString()}`)}${
              item?.moving ? ` ${t("check.cuc.moving")}` : ""
            }${item?.lowered ? ` ${t("check.cuc.lowered")}` : ""}`
          : ""
      }
    />
  );
};
