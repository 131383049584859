import "./Robots.scss";
import { ItemsListDetail } from "../../components";
import { useEffect, useState } from "react";
import { RobotsListView } from "./RobotsListView";
import { usePagination } from "../../hooks";
import { RobotsFilterModal } from "./RobotsFilterModal";
import { RobotView } from "./robotView/RobotView";
import {
  OrderDto,
  PaginatedListDtoOfRobotAdminListDto,
  RobotAdminFilterCriteria,
  RobotAdminFilteringDataDto,
  RobotDefaultConfigurationDto,
  orderDtoDefault,
} from "../../models";
import { useSearchParams } from "react-router-dom";
import {
  getDefaultConfiguration,
  getFilteringData,
  getRobots,
} from "./robots.service";
import { useTranslation } from "react-i18next";
import { ErrorHelper, ToastHelper } from "../../helpers";

export const Robots = () => {
  const [isLeftSideWorking, setIsLeftSideWorking] = useState(false);
  const [lastFilter, setLastFilter] = useState("--");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [page, setPage] = useState<
    PaginatedListDtoOfRobotAdminListDto | undefined
  >(undefined);
  const [filter, setFilter] = useState<RobotAdminFilterCriteria>({
    ...robotAdminFilterCriteriaDefault,
  });
  const [filteringData, setFilteringData] = useState<
    RobotAdminFilteringDataDto | undefined
  >(undefined);
  const [state, setState] = useState({ ...robotsStateDefault });
  const [robotDefaultConfiguration, setRobotDefaultConfiguration] = useState<
    RobotDefaultConfigurationDto | undefined
  >(undefined);

  const [searchParams] = useSearchParams();

  const {
    pageValues,
    itemsPerPageChange,
    totalItemsChange,
    goNext,
    goPrevious,
    setCurrentPage,
  } = usePagination();

  const { t } = useTranslation();

  useEffect(() => {
    getDataForFiltering();
    getRobotDefaultConfiguration();
  }, []);

  useEffect(() => {
    if (!page) return;

    if (page.items.some((x) => x.id === state.currentId)) return;

    setState({
      ...state,
      currentId: page.items.length === 0 ? undefined : page.items[0].id,
    });
  }, [page, state]);

  useEffect(() => {
    const strFilter = JSON.stringify(filter);
    if (lastFilter == strFilter || isFirstTime) return;

    setLastFilter(strFilter);
    search(filter, totalItemsChange);
  }, [filter, lastFilter, isFirstTime, totalItemsChange]);

  useEffect(() => {
    if (
      pageValues.currentPage === filter.page &&
      pageValues.itemsPerPage === filter.maxItemCount
    )
      return;

    setFilter({
      ...filter,
      page: pageValues.currentPage,
      maxItemCount: pageValues.itemsPerPage,
    });
  }, [pageValues, filter]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id && isFirstTime) {
      setFilter({
        ...filter,
        robotIds: [id],
      });
    }
    setIsFirstTime(false);
  }, [searchParams, isFirstTime, filter]);

  const getDataForFiltering = async () => {
    try {
      setFilteringData(await getFilteringData());
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const getRobotDefaultConfiguration = async () => {
    try {
      setRobotDefaultConfiguration(await getDefaultConfiguration());
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const search = async (
    f: RobotAdminFilterCriteria,
    ti: (num: number) => void,
  ) => {
    let itemsReceived = 0;
    let totalItems = 0;

    setIsLeftSideWorking(true);

    try {
      const newPage = await getRobots(f);

      itemsReceived = newPage.items.length;
      totalItems = newPage.totalItems;

      setPage(newPage);
      ti(newPage.totalItems);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsLeftSideWorking(false);

    return { itemsReceived, totalItems };
  };

  const dataOrderChange = (od: OrderDto) => {
    setState({ ...state, order: od });
    setFilter({
      ...filter,
      page: 1,
      orderBy: od.order,
    });
  };

  const currentIdChange = (id: string | undefined) => {
    if (state.currentId === id) return;
    setState({ ...state, currentId: id });
  };

  const setIsFilterModalOpen = (open: boolean) => {
    if (state.isFilterModalOpen === open) return;
    setState({ ...state, isFilterModalOpen: open });
  };

  const applyModalSelection = (selected: RobotAdminFilterCriteria) => {
    setIsFilterModalOpen(false);
    setFilter({
      ...filter,
      robotIds: selected.robotIds,
      robotNames: selected.robotNames,
      robotLocations: selected.robotLocations,
      antSerialNumbers: selected.antSerialNumbers,
      cleanfixSerialNumbers: selected.cleanfixSerialNumbers,
      licenses: selected.licenses,
      robotTypes: selected.robotTypes,
      tenants: selected.tenants,
      antVersions: selected.antVersions,
      hmiVersions: selected.hmiVersions,
    });
    setCurrentPage(1);
  };

  const refreshList = async () => {
    await refreshFullList();
  };

  const refreshFullList = async () => {
    const { itemsReceived, totalItems } = await search(
      filter,
      totalItemsChange,
    );

    if (filter.page === 0 || itemsReceived > 0 || totalItems === 0) return;
    goPrevious();
  };

  const textSearchChange = (searchText: string) => {
    setCurrentPage(1);
    setFilter({ ...filter, searchText: searchText });
  };

  return (
    <ItemsListDetail
      title={{
        title: t("adminPanel.robots.title"),
        textSearch: textSearchChange,
        canFilterButton: true,
        textSearchToolTip: t("adminPanel.robots.textSearchToolTip"),
        filterButtonToolTip: t("adminPanel.robots.filterButtonToolTip"),
        filterButtonClick: () => setIsFilterModalOpen(true),
      }}
      paginator={{
        itemsPerPage: pageValues.itemsPerPage,
        currentPage: pageValues.currentPage,
        totalItems: pageValues.totalItems,
        onNextPage: () => goNext(),
        onPreviousPage: () => goPrevious(),
        onItemsPerPageChange: (x) => itemsPerPageChange(x),
      }}
      leftSide={{
        className: "robots-left-side",
        component: (
          <>
            <RobotsListView
              items={page?.items ?? []}
              dataOrder={state.order}
              onDataOrderChange={dataOrderChange}
              currentId={state.currentId}
              onCurrentIdChange={currentIdChange}
              filter={filter}
              isWorking={isLeftSideWorking}
              onBadgeChange={applyModalSelection}
              onRobotAdded={refreshList}
              onRefresh={refreshList}
              robotDefaultConfiguration={robotDefaultConfiguration}
            />

            {state.isFilterModalOpen && filteringData && (
              <RobotsFilterModal
                onClose={() => setIsFilterModalOpen(false)}
                filteringData={filteringData}
                filter={filter}
                onApply={applyModalSelection}
              />
            )}
          </>
        ),
      }}
      rightSide={{
        className: "robots-right-side",
        component: (
          <RobotView currentId={state.currentId} onRefreshList={refreshList} />
        ),
      }}
    />
  );
};

const robotAdminFilterCriteriaDefault: RobotAdminFilterCriteria = {
  robotIds: [],
  searchText: undefined,
  robotNames: [],
  robotLocations: [],
  antSerialNumbers: [],
  cleanfixSerialNumbers: [],
  licenses: [],
  robotTypes: [],
  tenants: [],
  antVersions: [],
  hmiVersions: [],
  page: 1,
  maxItemCount: 25,
  orderBy: "desc",
};

interface RobotsState {
  currentId: string | undefined;
  isFilterModalOpen: boolean;
  order: OrderDto;
}

const robotsStateDefault: RobotsState = {
  currentId: undefined,
  isFilterModalOpen: false,
  order: {
    ...orderDtoDefault,
    orderColumns: "cleanfixSerialNumber",
  },
};
