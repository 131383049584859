import {
  PaginatedListDtoOfRobotAdminListDto,
  RobotAdminDto,
  RobotAdminFilterCriteria,
  RobotAdminFilteringDataDto,
  RobotDefaultConfigurationDto,
} from "../../models";
import { axiosGet, axiosPut, objectToQueryString } from "../../services";

const url = "admin/v2/robots";

export const getRobots = async (filter: RobotAdminFilterCriteria) => {
  const queryString = objectToQueryString(filter);
  return (await axiosGet(`${url}?${queryString}`))
    .data as PaginatedListDtoOfRobotAdminListDto;
};

export const getFilteringData = async () => {
  return (await axiosGet(`${url}/filtering-data`))
    .data as RobotAdminFilteringDataDto;
};

export const getRobot = async (id: string) => {
  return (await axiosGet(`${url}/${id}`)).data as RobotAdminDto;
};

export const getDefaultConfiguration = async () => {
  return (await axiosGet(`${url}/default-configuration`))
    .data as RobotDefaultConfigurationDto;
};

export const startReceivingCucData = async (robotId: string) => {
  await axiosPut(`${url}/${robotId}/cuc-data`, {});
};
