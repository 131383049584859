export const TextValue = (props: TextValueProps) => (
  <>
    <div className={`col-${props?.columns ?? 6} ps-5 mb-3`}>
      <span>{props.text}</span>
    </div>
    <div className={`col-${12 - (props?.columns ?? 6)} text-end mb-3`}>
      {props.value}
    </div>
  </>
);

interface TextValueProps {
  text: string;
  value: number | string | undefined | null;
  columns?: number;
  className?: string;
}
