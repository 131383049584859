import { useTranslation } from "react-i18next";
import { SubTitle, TextContainer } from "./common";
import { useContext, useState } from "react";
import { RobotViewContext } from "./RobotViewContext";
import {
  RobotAdminChargingStationSettingsDto,
  RobotAdminValveSettings,
} from "../../../models";
import { RobotViewChargingModal } from "./RobotViewChargingModal";
import { TextBox } from "../../../components";

export const RobotViewCharging = () => {
  const { t } = useTranslation();

  const { robotAdmin } = useContext(RobotViewContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!robotAdmin) return <></>;

  const { charginAssistantMode, charginAssistantAddress } =
    charginAssistantTexts(robotAdmin.chargingStationSettings);

  const { valveSettingsMode } = valveTexts(robotAdmin.valveSettings);

  return (
    <div className="row charging-tab">
      <SubTitle
        text={t("adminPanel.robots.charging.chargingAssistant.title")}
      />
      <TextContainer
        text={t("adminPanel.robots.charging.chargingAssistant.connectionType")}
        className="align-self-center"
      >
        <>
          {charginAssistantMode && (
            <div className="rectangle text-center">{charginAssistantMode}</div>
          )}
        </>
      </TextContainer>
      <TextContainer
        text={t("adminPanel.robots.charging.chargingAssistant.address")}
        className="align-self-center"
      >
        <span>{charginAssistantAddress}</span>
      </TextContainer>

      <SubTitle text={t("adminPanel.robots.charging.valve.title")} />
      <TextContainer
        text={t("adminPanel.robots.charging.valve.connectionType")}
        className="align-self-center"
      >
        <>
          {valveSettingsMode && (
            <div className="rectangle text-center">{valveSettingsMode}</div>
          )}
        </>
      </TextContainer>

      {!robotAdmin.valveSettings.wifiMode && (
        <>
          <TextContainer
            text={t("adminPanel.robots.charging.valve.address")}
            className="align-self-center"
          >
            <span>{robotAdmin.valveSettings.bleAddress}</span>
          </TextContainer>
        </>
      )}

      <SubTitle text={t("adminPanel.robots.charging.pairWith")} />

      <div className="col-12 mb-3">
        <TextBox type="info">
          <>{t("adminPanel.robots.charging.pairInfo")}</>
        </TextBox>
      </div>

      <div className="col-12 text-end">
        <button
          className="btn btn-outline-cleanfix-no-border"
          onClick={() => setIsModalOpen(true)}
          disabled={!robotAdmin.cloudConnection}
        >
          {t("adminPanel.robots.charging.pair")}
        </button>
      </div>

      {isModalOpen && (
        <RobotViewChargingModal onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
};

const charginAssistantTexts = (
  chargingStationSettings: RobotAdminChargingStationSettingsDto,
) => {
  let charginAssistantMode = undefined;
  let charginAssistantAddress = undefined;

  if (
    chargingStationSettings.wifiMode &&
    chargingStationSettings.chargingStationSsid
  ) {
    charginAssistantMode = "WIFI";
    charginAssistantAddress = chargingStationSettings.chargingStationSsid;
  } else if (
    !chargingStationSettings.wifiMode &&
    chargingStationSettings.bleAddress
  ) {
    charginAssistantMode = "BLE";
    charginAssistantAddress = chargingStationSettings.bleAddress;
  }

  return { charginAssistantMode, charginAssistantAddress };
};

const valveTexts = (valveSettings: RobotAdminValveSettings) => {
  let valveSettingsMode = undefined;

  if (valveSettings.wifiMode) {
    valveSettingsMode = "WIFI";
  } else if (!valveSettings.wifiMode && valveSettings.bleAddress) {
    valveSettingsMode = "BLE";
  }

  return { valveSettingsMode };
};
