export const addToStringArray = (
  array: string[] | null | undefined,
  newValue: string,
) => {
  const result = [...(array ?? [])];
  return result.some((x) => x === newValue) ? result : [...result, newValue];
};

export const removeToStringArray = (
  array: string[] | null | undefined,
  newValue: string,
) => {
  return [...(array ?? [])].filter((x) => x !== newValue);
};
