import { diff, jsonPatchPathConverter } from "just-diff";
import { RobotAdminSettingsDto, RobotTimeZone } from "../../../models";
import { axiosGet, axiosPatch } from "../../../services";

const timezonesUrl = "admin/v2/timezones";

export const getTimeZones = async () => {
  return (await axiosGet(timezonesUrl)).data as RobotTimeZone[];
};

const robotUrl = "admin/v2/robots";

export const patchSettings = async (
  robotId: string,
  originalDto: RobotAdminSettingsDto,
  newDto: RobotAdminSettingsDto,
) => {
  const allowProperties = [
    "/pin",
    "/timeZoneInfoId",
    "/language",
    "/antServerModeEnabled",
    "/dryModeEnabled",
    "/missionChargingBehavior",
    "/remoteSupportEnabled",
  ];

  const jsonPatch = diff(originalDto, newDto, jsonPatchPathConverter).filter(
    (x) => allowProperties.includes(x.path),
  );

  await axiosPatch(`${robotUrl}/${robotId}/settings`, jsonPatch);
};
