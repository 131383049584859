import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

export const NumericInput = (props: numericInputProps) => {
  const [filedValue, setFieldValue] = useState(props.value?.toString());

  useEffect(() => {
    setFieldValue(props.value?.toString());
  }, [props.value]);

  const valueChange = (value: string) => {
    if (!separatorList[separatorType].regex.test(value)) {
      setFieldValue("");
      props.ret(0);
      return;
    }

    setFieldValue(value);

    let nValue =
      separatorType === "no" ? parseInt(value, 10) : parseFloat(value);

    if (props.minValue && nValue < props.minValue) {
      nValue = props.minValue;
      setFieldValue(nValue.toString());
    }

    if (props.maxValue && nValue > props.maxValue) {
      nValue = props.maxValue;
      setFieldValue(nValue.toString());
    }

    props.ret(nValue);
  };

  const separatorType = props.decimalPositions > 0 ? "period" : "no";

  return (
    <TextField
      placeholder={props.placeholder}
      label=""
      fullWidth
      multiline
      variant="standard"
      margin="dense"
      className={`w-100 ${props.className ?? ""}`}
      hiddenLabel
      value={filedValue}
      disabled={props.disabled}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      onChange={({ target: { value } }) => valueChange(value)}
    />
  );
};

export interface numericInputProps {
  value?: number;
  decimalPositions: number;
  maxValue?: number;
  minValue?: number;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  ret: (newValue: number) => void;
}

NumericInput.defaultProps = {
  decimalPositions: 0,
};

const separatorList = {
  no: {
    name: "comma",
    regex: /^\d+$/,
  },
  comma: {
    name: "comma",
    regex: /^\d+(,\d{0,2})?$/,
  },
  period: {
    name: "period",
    regex: /^\d+(\.\d{0,2})?$/,
  },
};
