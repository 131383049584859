import { ChargingAssistantConfigPostDto } from "../../../models";
import { axiosPut } from "../../../services";

const robotUrl = "admin/v2/robots";

export const putChargingAssistant = async (
  robotId: string,
  originalDto: ChargingAssistantConfigPostDto,
) => {
  await axiosPut(`${robotUrl}/${robotId}/charging-assistant`, originalDto);
};
