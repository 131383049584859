import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import BugReportIcon from "@mui/icons-material/BugReport";
import { FilterBar } from "../filterBar";
import { TooltipContainer } from "../tooltipContainer";
import "./TitleBar.scss";

export const TitleBar = (props: TitleBarProps) => {
  const textSearch = (textToSearch: string) => {
    if (!props.textSearch) return;
    props.textSearch(textToSearch);
  };

  const filterButtonClick = () => {
    if (!props.filterButtonClick) return;
    props.filterButtonClick();
  };

  const refreshButtonClick = () => {
    if (!props.refreshButtonClick) return;
    props.refreshButtonClick();
  };

  const toggleInvalidMissionsButtonClick = () => {
    if (!props.toggleInvalidMissionsButtonClick) return;
    props.toggleInvalidMissionsButtonClick();
  };

  return (
    <div className="col-12 title-bar-container">
      <div className="row py-2 px-2">
        <div className="col align-self-center">
          <span className="title">
            {props.isToggleInvalidMissionsActivated
              ? `${props.title} with invalid missions`
              : props.title}
          </span>
        </div>
        {props.canTextSearch && (
          <div className="col text-search-column align-self-center">
            {props.textSearchToolTip && (
              <TooltipContainer text={props.textSearchToolTip}>
                <FilterBar search={textSearch} />
              </TooltipContainer>
            )}
            {!props.textSearchToolTip && <FilterBar search={textSearch} />}
          </div>
        )}
        {props.canFilterButton && (
          <div className="col filter-button-column px-0 align-self-center">
            <TooltipContainer text={props.filterButtonToolTip ?? ""}>
              <FilterAltOutlinedIcon
                className="icon-button"
                onClick={filterButtonClick}
              />
            </TooltipContainer>
          </div>
        )}
        {props.canRefreshButton && (
          <div className="col filter-button-column px-0 align-self-center">
            <TooltipContainer text={props.refreshButtonToolTip ?? ""}>
              <RefreshRoundedIcon
                className="icon-button"
                onClick={refreshButtonClick}
              />
            </TooltipContainer>
          </div>
        )}
        {props.canToggleInvalidMissionsButton && (
          <div className="col filter-button-column px-0 align-self-center">
            <TooltipContainer
              text={props.toggleInvalidMissionsButtonToolTip ?? ""}
            >
              <BugReportIcon
                className={
                  props.isToggleInvalidMissionsActivated
                    ? "icon-button-active"
                    : "icon-button"
                }
                onClick={toggleInvalidMissionsButtonClick}
              />
            </TooltipContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export interface TitleBarProps {
  title: string;
  canTextSearch?: boolean;
  textSearchToolTip?: string | null;
  textSearch?: (textToSearch: string) => void;
  canFilterButton?: boolean;
  filterButtonToolTip?: string | null | undefined;
  filterButtonClick?: () => void;
  canRefreshButton?: boolean;
  refreshButtonToolTip?: string | null | undefined;
  refreshButtonClick?: () => void;
  canToggleInvalidMissionsButton?: boolean;
  toggleInvalidMissionsButtonToolTip?: string | null | undefined;
  toggleInvalidMissionsButtonClick?: () => void;
  isToggleInvalidMissionsActivated?: boolean;
}

TitleBar.defaultProps = {
  canTextSearch: true,
  canFilterButton: false,
  canRefreshButton: false,
};
