/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { RawAxiosRequestHeaders } from "axios";
import { acquireAccessToken, authProvider } from "./auth";

export const axiosGet = async (url: string, withBearer = true) => {
  return axios.get(url, {
    baseURL: getBaseUrlApi(),
    headers: await generateHeader(withBearer),
  });
};

export const axiosPatch = async (
  url: string,
  jsonPatch: any,
  withBearer = true,
) => {
  return axios.patch(url, jsonPatch, {
    baseURL: getBaseUrlApi(),
    headers: await generateHeader(withBearer),
  });
};

export const axiosPost = async (
  url: string,
  jsonBody: any,
  withBearer = true,
) => {
  return axios.post(url, jsonBody, {
    baseURL: getBaseUrlApi(),
    headers: await generateHeader(withBearer),
  });
};

export const axiosPut = async (
  url: string,
  jsonBody: any,
  withBearer = true,
) => {
  return axios.put(url, jsonBody, {
    baseURL: getBaseUrlApi(),
    headers: await generateHeader(withBearer),
  });
};

export const axiosDelete = async (url: string, withBearer = true) => {
  return axios.delete(url, {
    baseURL: getBaseUrlApi(),
    headers: await generateHeader(withBearer, "*/*"),
  });
};

export const objectToQueryString = (object: any) => {
  let result = "";
  let isfirst = true;

  Object.keys(object).forEach((propertyName) => {
    if (object[propertyName]) {
      if (Array.isArray(object[propertyName])) {
        object[propertyName].forEach((y: any) => {
          result += objectToQueryStringAdd(propertyName, y, isfirst);
          isfirst = false;
        });
      } else {
        result += objectToQueryStringAdd(
          propertyName,
          object[propertyName],
          isfirst,
        );
        isfirst = false;
      }
    }
  });

  return result;
};

const objectToQueryStringAdd = (
  propertyName: string,
  value: any,
  isFirst: boolean,
) => {
  return `${isFirst ? "" : "&"}${propertyName}=${
    value instanceof Date ? dateToString(value) : value
  }`;
};

const dateToString = (date: Date) => {
  return `${date.getFullYear().toString().padStart(4, "0")}-${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};

const generateHeader = async (withBearer: boolean, accept?: string) => {
  let header: RawAxiosRequestHeaders = {
    Accept: accept ?? "application/json",
  };

  if (withBearer) {
    const token = await acquireAccessToken(authProvider);

    header = {
      ...header,
      Authorization: `Bearer ${token}`,
    };
  }

  return header;
};

const getBaseUrlApi = () => {
  return `${getBaseUrl()}api/`;
};

// TODO: what is this port used for, we need to check this section.
export const getBaseUrl = () => {
  return window.location.host.startsWith("127.0.0")
    ? "https://localhost:44367/"
    : `${window.location.protocol}//${window.location.host}/`;
};
