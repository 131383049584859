export const keysMissionsCount = [
  "Completed",
  "PartiallyCompleted",
  "InProgress",
  "SendHome",
  "Aborted",
  "FailedAtHomeNode",
  "FailedDistanceSensorRecovery",
  "FailedTimeout",
  "FailedTransitToStartNode",
  "FailedUnknown",
];
export type KeysMissionsRate = {
  successful: string;
  failed: string;
  unknown: string;
};

export const colorMapping = {
  successful: "#5CB270", // Green
  failed: "#E9680E", // Red
  unknown: "#FFC107", // Amber
};

export type ColorMappingMissionCount = {
  Completed: string;
  PartiallyCompleted: string;
  InProgress: string;
  SendHome: string;
  Aborted: string;
  FailedAtHomeNode: string;
  FailedDistanceSensorRecovery: string;
  FailedTimeout: string;
  FailedTransitToStartNode: string;
  FailedUnknown: string;
};

export const colorMappingMissionCount = {
  // Success Colors: https://coolors.co/palette/F4F269-CEE26B-A8D26D-82C26E-5CB270
  SendHome: "#CEE26B",
  PartiallyCompleted: "#82C26E",
  Completed: "#5CB270",
  Aborted: "#F4F269",
  InProgress: "#A8D26D",
  // Failed Colors: https://coolors.co/gradient-palette/a73939-ff7700?number=5
  FailedUnknown: "#A73939",
  FailedTransitToStartNode: "#BD492B",
  FailedTimeout: "#D3581D",
  FailedDistanceSensorRecovery: "#E9680E",
  FailedAtHomeNode: "#FF7700",
};

export interface MissionData {
  month: string;
  Completed?: number;
  PartiallyCompleted?: number;
  InProgress?: number;
  SendHome?: number;
  Aborted?: number;
  FailedAtHomeNode?: number;
  FailedDistanceSensorRecovery?: number;
  FailedTimeout?: number;
  FailedTransitToStartNode?: number;
  FailedUnknown?: number;
  [key: string]: string | number | undefined; // Add index signature
}

export interface MissionRateData {
  month: string;
  successful: number;
  failed: number;
  unknown: number;
  [key: string]: string | number | undefined; // Add index signature
}
