import {
  FleetStatisticsDto,
  MissionStatisticsDto,
  RobotDetailStatisticsDto,
  RobotStatisticsDto,
} from "../../models";
import { axiosGet } from "../../services";

const simUrl = "admin/v2/Statistics/";

export const getMissionStatisticsData = async (
  statisticsSinceDate: string,
  useCache: boolean,
  toggleInvalidMissions: boolean,
) => {
  const missionStatistics = (
    await axiosGet(
      `${simUrl}missions?statisticsSinceDate=${statisticsSinceDate}&useCache=${useCache}&toggleInvalidMissions=${toggleInvalidMissions}`,
    )
  ).data as MissionStatisticsDto;
  // order missionSuccessRatePerMonth by month
  missionStatistics.missionSuccessRatePerMonth =
    missionStatistics.missionSuccessRatePerMonth.sort(
      (a, b) => new Date(a.month).getTime() - new Date(b.month).getTime(),
    );

  // order missionsPerStatusAndMonth by month
  missionStatistics.missionsPerStatusAndMonth =
    missionStatistics.missionsPerStatusAndMonth.sort(
      (a, b) => new Date(a.month).getTime() - new Date(b.month).getTime(),
    );

  return missionStatistics;
};

export const getFleetStatisticsData = async (
  statisticsSinceDate: string,
  activityThresholdDays: number,
  failedReasonsForLastXDays: number,
  useCache: boolean,
  toggleInvalidMissions: boolean,
) => {
  const fleetStatistics = (
    await axiosGet(
      `${simUrl}fleet?statisticsSinceDate=${statisticsSinceDate}&activityThresholdDays=${activityThresholdDays}&failedReasonsForLastXDays=${failedReasonsForLastXDays}&useCache=${useCache}&toggleInvalidMissions=${toggleInvalidMissions}`,
    )
  ).data as FleetStatisticsDto;

  // order fleetStatisticPerMonth by month
  fleetStatistics.fleetStatisticPerMonth =
    fleetStatistics.fleetStatisticPerMonth.sort(
      (a, b) => new Date(a.month).getTime() - new Date(b.month).getTime(),
    );

  return fleetStatistics;
};

export const getRobotsStatisticsData = async (
  useCache: boolean,
  toggleInvalidMissions: boolean,
) => {
  const lastXDays = 30;
  return (
    await axiosGet(
      `${simUrl}robots/?lastXDays=${lastXDays}&useCache=${useCache}&toggleInvalidMissions=${toggleInvalidMissions}`,
    )
  ).data as RobotStatisticsDto[];
};

export const getRobotDetailStatisticsData = async (
  id: string,
  toggleInvalidMissions: boolean,
) => {
  const lastXDays = 30;
  return (
    await axiosGet(
      `${simUrl}robots/${id}?failedReasonsForLastXDays=${lastXDays}&useCache=false&toggleInvalidMissions=${toggleInvalidMissions}`,
    )
  ).data as RobotDetailStatisticsDto;
};
