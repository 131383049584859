import TextField from "@mui/material/TextField";

export const TextInput = (props: textInputProps) => {
  if (!props.multiline) {
    return (
      <TextField
        label=""
        placeholder={props.placeholder}
        variant="standard"
        margin="dense"
        hiddenLabel
        className={`w-100 ${props.className ?? ""}`}
        disabled={props.disabled}
        value={props.value}
        onChange={(event) => props.ret(event.target.value)}
        onKeyDown={(event) => {
          if (!props.onKeyDown) return;
          props.onKeyDown(event.key, event.ctrlKey, event.altKey);
        }}
      />
    );
  }

  return (
    <TextField
      placeholder={props.placeholder}
      label=""
      fullWidth
      multiline
      variant="standard"
      margin="dense"
      className={`w-100 ${props.className ?? ""}`}
      minRows={2}
      hiddenLabel
      disabled={props.disabled}
      value={props.value}
      onChange={(event) => props.ret(event.target.value)}
      onKeyDown={(event) => {
        if (!props.onKeyDown) return;
        props.onKeyDown(event.key, event.ctrlKey, event.altKey);
      }}
    />
  );
};

export interface textInputProps {
  value?: string;
  multiline?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  ret: (newValue: string) => void;
  onKeyDown?: (key: string, crtl: boolean, alt: boolean) => void;
}

TextInput.defaultProps = {
  placeholder: null,
};
