/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { ReactElement } from "react";
import { OrderDto } from "../../models";

export function TableGeneric<T>(props: tableGenericProps<T>) {
  const handleItemClick = (item: T) => {
    if (props.itemClick) {
      props.itemClick(item);
    }
  };

  const handleHeaderClick = (item: tableGenericColumn) => {
    if (!props.headerClick || !props.itemsOrder) {
      return;
    }

    if (props.itemsOrder.orderColumns === item.property) {
      const newOrder: OrderDto = {
        ...props.itemsOrder,
        order: props.itemsOrder.order === "asc" ? "desc" : "asc",
      };

      props.headerClick(item, newOrder);
    } else {
      const newOrder: OrderDto = {
        ...props.itemsOrder,
        orderColumns: item.property ?? "",
        order: props.itemsOrder.order === "asc" ? "desc" : "asc",
      };

      props.headerClick(item, newOrder);
    }
  };

  const propertytoTitle = (propertyName?: string) => {
    if (!propertyName) {
      return "";
    }

    const title = propertyName.charAt(0).toUpperCase() + propertyName.slice(1);

    return title.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ");
  };

  let headerCounter = 0;
  const headerKey = (propertyName?: string) => {
    headerCounter++;
    return propertyName ?? `header_${headerCounter}`;
  };

  let rowCounter = 0;
  const rowKey = (property: string, row: any) => {
    rowCounter++;
    return currentRowKey(property, row);
  };

  const currentRowKey = (property: string, row: any) => {
    return (property ? row[property] : undefined) ?? `row_${rowCounter}`;
  };

  const cellKey = (row: any, column: tableGenericColumn) => {
    return `${currentRowKey(props.idProperty, row)}_${
      column.property ?? props.columns.indexOf(column)
    }`;
  };

  return (
    <Table stickyHeader className={`${props.tableClassName ?? "px-0"}`}>
      {props.showHeader && (
        <TableHead>
          <TableRow>
            {props.columns.map((item) => (
              <TableCell
                key={headerKey(item.property)}
                className={`${item.headerClassName ?? ""} header-cell`}
                onClick={() => {
                  if (item.canSort !== false && item.property) {
                    handleHeaderClick(item);
                  }
                }}
              >
                <table
                  className={`${
                    item.canSort !== false && item.property
                      ? "cursor-pointer"
                      : ""
                  } w-100`}
                >
                  <tbody>
                    <tr>
                      <td>{item.title ?? propertytoTitle(item.property)}</td>
                      <td
                        className={
                          item.canSort ? "text-end cursor-pointer" : "text-end"
                        }
                      >
                        {!props.itemsOrder ||
                        item.property !== props.itemsOrder.orderColumns ? (
                          <></>
                        ) : props.itemsOrder.order === "asc" ? (
                          <ArrowDropUpOutlinedIcon className="vertical-center" />
                        ) : (
                          <ArrowDropDownOutlinedIcon className="vertical-center" />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}

      <TableBody>
        {props.children
          ? props.children
          : props.items.map((row: any) => (
              <TableRow
                hover
                key={rowKey(props.idProperty, row)}
                onClick={() => handleItemClick(row)}
              >
                {props.columns.map((column: any) => (
                  <TableCell
                    key={cellKey(row, column)}
                    className={`${
                      (column.itemClassName ?? "") +
                      (props.currentId === row[props.idProperty]
                        ? props.currentRowCssClass
                        : "")
                    } table-cell`}
                  >
                    {column.cellTemplate
                      ? column.cellTemplate(row)
                      : column.property
                      ? row[column.property]
                      : ""}
                  </TableCell>
                ))}
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
}

interface tableGenericProps<T> {
  tableClassName?: string;
  showHeader?: boolean;
  columns: tableGenericColumn[];
  items: T[];
  idProperty?: any;
  itemsOrder?: OrderDto;
  currentId?: any;
  headerClick?: (header: tableGenericColumn, itemsOrder: OrderDto) => void;
  itemClick?: (item: T) => void;
  children?: ReactElement | never[] | ReactElement[];
  currentRowCssClass?: string;
}

export interface tableGenericColumn {
  title?: string | undefined | null;
  property?: string;
  headerClassName?: string;
  itemClassName?: string;
  canSort?: boolean;
  cellTemplate?: (item: any) => ReactElement;
}

TableGeneric.defaultProps = {
  showHeader: true,
  currentRowCssClass: " current-row ",
};
