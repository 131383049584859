import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Loading,
  ModalWindow,
  TextInput,
  TooltipButton,
} from "../../../components";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { getSimData, postSim, putSimAssign } from "./RobotViewSim.service";
import Switch from "@mui/material/Switch/Switch";
import { SimRegisterPostDto } from "../../../models";
import { getFilteringData } from "../robots.service";

export const RobotViewSimAssignModal = (
  props: RobotViewSimAssignModalProps,
) => {
  const { t } = useTranslation();
  const [isWorking, setIsWorking] = useState(false);
  const [usedWithRobot, setUsedWithRobot] = useState<string[] | undefined>(
    undefined,
  );
  const [iccid, setIccid] = useState("");
  const [isAutoRenew, setIsAutoRenew] = useState(true);
  const [alias, setAlias] = useState(props.routerSerialNumber);
  const [isSimRegistered, setIsSimRegistered] = useState<boolean | undefined>(
    undefined,
  );
  const [assignedSims, setAssignedSims] = useState<string[][]>([]);

  useEffect(() => {
    getAssignedSims();
  }, []);

  const navigateToRobot = () => {
    if (usedWithRobot && props.robotId !== usedWithRobot[1]) {
      window.location.href += `?id=${usedWithRobot[1]}`;
    }
  };

  const checkIccid = async () => {
    try {
      const isRegistered = !!(await getSimData(iccid)) ? true : false;
      setIsSimRegistered(isRegistered);
      if (isRegistered)
        setUsedWithRobot(assignedSims.find((e) => e[0] === iccid));
    } catch (error) {
      setIsSimRegistered(false);
    }
  };

  const addSim = async () => {
    setIsWorking(true);

    try {
      await putSimAssign(props.robotId, iccid);
      ToastHelper.success(t("adminPanel.notifications.success"));
      props.assigned();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const registerAndAddSim = async () => {
    setIsWorking(true);

    const data: SimRegisterPostDto = {
      iccid: iccid,
      simAlias: alias,
      autoRenew: isAutoRenew,
      robotId: props.robotId,
    };

    try {
      await postSim(data);
      ToastHelper.success(t("adminPanel.notifications.success"));
      props.assigned();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const getAssignedSims = async () => {
    setIsWorking(true);
    try {
      setAssignedSims(
        (await getFilteringData()).iccids.map((e) => e.split(";")),
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
    setIsWorking(false);
  };

  return (
    <ModalWindow
      size={"xs"}
      onClose={props.onClose}
      title={t("adminPanel.robots.sim.addSim.title")}
      footer={
        <>
          <button
            className="btn btn-cleanfix text-uppercase me-2"
            onClick={props.onClose}
          >
            {t("common.cancel")}
          </button>

          <button
            style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
            disabled={isSimRegistered === undefined || !!usedWithRobot}
            className="btn btn-cleanfix text-uppercase "
            onClick={() => {
              isSimRegistered ? addSim() : registerAndAddSim();
            }}
          >
            {isSimRegistered === undefined || isSimRegistered
              ? t("adminPanel.robots.sim.simActions.addSim")
              : t("adminPanel.robots.sim.simActions.registerAndAddSim")}
          </button>
        </>
      }
    >
      <>
        {isWorking && (
          <div className="row py-5">
            <Loading size={100} />
          </div>
        )}

        {!isWorking && (
          <>
            <div className="row mb-4">
              <div
                className="col pt-1 pe-0 me-4 modal-label"
                style={{ maxWidth: "max-content" }}
              >
                ICCID
              </div>
              <div className="col ps-0 ms-0">
                <TextInput
                  className="text-start my-0"
                  value={iccid}
                  ret={(x) => {
                    setIccid(x);
                    setIsSimRegistered(undefined);
                    setUsedWithRobot(undefined);
                  }}
                />
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-outline-cleanfix-no-border "
                  style={{ maxWidth: "max-content" }}
                  onClick={checkIccid}
                >
                  {t("adminPanel.robots.sim.simActions.check")}
                </button>
              </div>
            </div>
            <div style={{ minHeight: "12em" }} className="mb-5">
              {isSimRegistered === false && (
                <>
                  <div className="row mb-4">
                    <div className="col-2 modal-label">
                      {t("adminPanel.robots.sim.common.alias")}
                    </div>
                    <div className="col-7">
                      <TextInput
                        className="text-start my-0"
                        value={alias}
                        ret={(x) => {
                          setAlias(x);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3 pt-2 modal-label">
                      {t("adminPanel.robots.sim.common.autorenew")}
                    </div>
                    <Switch
                      checked={isAutoRenew}
                      onChange={() => setIsAutoRenew((prev) => !prev)}
                      inputProps={{ "aria-label": "controlled-switch" }}
                      name="autorenew"
                      size="medium"
                    />
                  </div>
                </>
              )}

              {isSimRegistered !== undefined &&
                (isSimRegistered
                  ? !!usedWithRobot
                    ? t("adminPanel.robots.sim.addSim.messageIsAssigned", {
                        iccid: iccid,
                        cleanfixSerialNumber: usedWithRobot[2],
                      })
                    : t("adminPanel.robots.sim.addSim.messageAdd", {
                        iccid: iccid,
                        cleanfixSerialNumber: props.cleanfixSerialNumber,
                      })
                  : t("adminPanel.robots.sim.addSim.messageRegisterAndAdd", {
                      iccid: iccid,
                      cleanfixSerialNumber: props.cleanfixSerialNumber,
                    }))}
              {isSimRegistered && !!usedWithRobot && (
                <TooltipButton
                  className="ps-1 mx-0"
                  tooltip={t("adminPanel.robots.sim.addSim.navigateToRobot")}
                  onClick={navigateToRobot}
                >
                  <InsertLinkIcon className="color-cleanfix" />
                </TooltipButton>
              )}
            </div>
          </>
        )}
      </>
    </ModalWindow>
  );
};

interface RobotViewSimAssignModalProps {
  robotId: string;
  cleanfixSerialNumber: string;
  routerSerialNumber: string;
  onClose: () => void;
  assigned: () => void;
}
