import { useEffect, useState } from "react";
import { getRobotDetailStatisticsData } from "../Dashboard.service";
import { ErrorHelper, ToastHelper } from "../../../helpers";
import { Loading } from "../../../components";
import { RobotDetailStatisticsDto, RobotStatisticsDto } from "../../../models";
import { ColorMappingMissionCount, colorMappingMissionCount } from "../Types";
import { formatWithApostrophes } from "../../../tools/number";

export const DashboardRobotDetailsView = (props: RobotDetailsViewProps) => {
  const { currentId, robotsStatistics } = props;
  const [isWorking, setIsWorking] = useState(false);
  const [robotStatistics, setRobotStatistics] = useState<
    RobotDetailStatisticsDto | undefined
  >(undefined);
  const [robotStatisticsLast30Days, setRobotStatisticsLast30Days] = useState<
    RobotStatisticsDto | undefined
  >(undefined);

  useEffect(() => {
    const getRobotStatistics = async () => {
      if (!currentId) return;
      setIsWorking(true);

      try {
        setRobotStatistics(
          await getRobotDetailStatisticsData(
            currentId,
            props.toggleInvalidMissions,
          ),
        );
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
      setIsWorking(false);
    };

    if (!currentId) return;

    if (robotsStatistics != undefined) {
      setRobotStatisticsLast30Days(
        robotsStatistics.find((e) => e.id === currentId),
      );
    }

    getRobotStatistics();
  }, [currentId, robotsStatistics, props.toggleInvalidMissions]);

  return (
    <>
      {isWorking && (
        <div className="col-12 item-list-loading text-center h-100">
          <Loading size={120} />
        </div>
      )}

      {!isWorking && robotStatistics && (
        <>
          <div className="container auto-scroll-y">
            <div className="row dashboard-component-labels me-0">
              <div className="col-6">
                <div>Name</div>
              </div>
              <div className="col-6 me-0 pe-0 text-end">
                <div>{robotStatistics.name}</div>
              </div>
            </div>

            <div className="row dashboard-component-labels me-0">
              <div className="col-6">
                <div>Tenant</div>
              </div>
              <div className="col-6 me-0 pe-0 text-end">
                <div>{robotStatistics.tenant}</div>
              </div>
            </div>

            <div className="row dashboard-component-labels me-0">
              <div className="col-6">
                <div>Cleanfix Serial Number</div>
              </div>
              <div className="col-6 me-0 pe-0 text-end">
                <div>{robotStatistics.cleanfixSerialNumber}</div>
              </div>
            </div>

            <div className="row dashboard-component-labels mt-4">
              <div className="col-12">
                <div className="my-2 dashboard-component-subtitle">
                  Stats Lifetime Total
                </div>
              </div>
            </div>

            <div className="row dashboard-component-labels me-0">
              <div className="col-8">
                <div>Area Cleaned</div>
              </div>
              <div className="col-4 me-0 pe-0 text-end">
                <div>
                  {formatWithApostrophes(robotStatistics.totalAreaCleaned)}
                </div>
              </div>
            </div>

            <div className="row dashboard-component-labels me-0">
              <div className="col-8">
                <div>Cleaning Time</div>
              </div>
              <div className="col-4 me-0 pe-0 text-end">
                <div>
                  {formatWithApostrophes(
                    robotStatistics.totalCleaningTime / 60,
                  )}
                </div>
              </div>
            </div>

            <div className="row dashboard-component-labels me-0">
              <div className="col-8">
                <div>Missions</div>
              </div>
              <div className="col-4 me-0 pe-0 text-end">
                <div>{robotStatistics.totalMissions}</div>
              </div>
            </div>

            <div className="row dashboard-component-labels me-0">
              <div className="col-8">
                <div>Failed Missions</div>
              </div>
              <div className="col-4 me-0 pe-0 text-end">
                <div>{robotStatistics.totalFailedMissions}</div>
              </div>
            </div>

            <div className="row dashboard-component-labels me-0">
              <div className="col-8">
                <div>Successful Missions</div>
              </div>
              <div className="col-4 me-0 pe-0 text-end">
                <div>
                  {robotStatistics.totalMissions -
                    robotStatistics.totalFailedMissions}
                </div>
              </div>
            </div>

            {robotStatisticsLast30Days != null && (
              <>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="my-2 dashboard-component-subtitle">
                      Stats Last 30 days
                    </div>
                  </div>
                </div>

                <div className="row dashboard-component-labels me-0">
                  <div className="col-8">
                    <div>Area Cleaned</div>
                  </div>
                  <div className="col-4 me-0 pe-0 text-end">
                    <div>
                      {formatWithApostrophes(
                        robotStatisticsLast30Days?.totalAreaCleaned,
                      )}
                    </div>
                  </div>
                </div>

                <div className="row dashboard-component-labels me-0">
                  <div className="col-8">
                    <div>Cleaning Time</div>
                  </div>
                  <div className="col-4 me-0 pe-0 text-end">
                    <div>
                      {formatWithApostrophes(
                        robotStatisticsLast30Days?.totalCleaningTime / 60,
                      )}
                    </div>
                  </div>
                </div>

                <div className="row dashboard-component-labels me-0">
                  <div className="col-8">
                    <div>Missions</div>
                  </div>
                  <div className="col-4 me-0 pe-0 text-end">
                    <div>{robotStatisticsLast30Days?.totalMissions}</div>
                  </div>
                </div>

                <div className="row dashboard-component-labels me-0">
                  <div className="col-8">
                    <div>Failed Missions</div>
                  </div>
                  <div className="col-4 me-0 pe-0 text-end">
                    <div>{robotStatisticsLast30Days?.totalFailedMissions}</div>
                  </div>
                </div>

                <div className="row dashboard-component-labels me-0">
                  <div className="col-8">
                    <div>Successful Missions</div>
                  </div>
                  <div className="col-4 pe-0 text-end">
                    <div>
                      {robotStatisticsLast30Days?.totalMissions -
                        robotStatisticsLast30Days?.totalFailedMissions}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {robotStatistics.failedReasonCountList.length > 0 && (
            <div className="container  mt-4">
              <div className="my-2 dashboard-component-subtitle">
                Mission Stats Last 30 Days
              </div>
              <div>
                {robotStatistics.failedReasonCountList.map((e) =>
                  e.count >= 0 ? (
                    <div
                      key={e.name}
                      className="row dashboard-component-labels mt-1 me-0"
                    >
                      <div className="col-10 my-0 tooltip-row-flex">
                        <div
                          className="legend-color-box"
                          style={{
                            backgroundColor:
                              colorMappingMissionCount[
                                e.name as keyof ColorMappingMissionCount
                              ] || "#FFFFFF",
                          }}
                        ></div>
                        {e.name.slice(0, 20)}
                      </div>
                      <div className="col-2 my-0 me-0 pe-0 text-end">
                        {e.count}
                      </div>
                    </div>
                  ) : null,
                )}
              </div>
            </div>
          )}
        </>
      )}
      {/* </div> */}
    </>
  );
};

export interface RobotDetailsViewProps {
  currentId: string | undefined;
  onRefreshList: (id?: string) => void;
  robotsStatistics: RobotStatisticsDto[] | undefined;
  toggleInvalidMissions: boolean;
}
