import { Box, Tab, Tabs, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RobotViewSystem } from "./RobotViewSystem";
import { RobotViewSettings } from "./RobotViewSettings";
import { RobotViewCharging } from "./RobotViewCharging";
import { useContext } from "react";
import { RobotViewContext } from "./RobotViewContext";
import { RobotViewSim } from "./RobotViewSim";
import { RobotViewCuc } from "./RobotViewCuc";

export const RobotViewTabs = () => {
  const { t } = useTranslation();

  const { uiConfig, currentTab, setCurrentTab } = useContext(RobotViewContext);

  const activeTab =
    currentTab === 4 && !uiConfig.pairingChargingStationEnabled
      ? 1
      : currentTab;

  return (
    <>
      <Box
        className="tab-header my-2"
        sx={{ borderBottom: 1, borderColor: "divider", boxShadow: 1 }}
      >
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("adminPanel.robots.tabs.system")} />
          <Tab label={t("adminPanel.robots.tabs.settings")} />
          <Tab label={t("adminPanel.robots.tabs.sim")} />
          <Tooltip title="Warning, disabled for production!">
            <Tab label={t("adminPanel.robots.tabs.cuc")} />
          </Tooltip>
          {uiConfig.pairingChargingStationEnabled && (
            <Tab label={t("adminPanel.robots.tabs.charging")} />
          )}
        </Tabs>
      </Box>
      {activeTab === 0 && <RobotViewSystem />}
      {activeTab === 1 && <RobotViewSettings />}
      {activeTab === 2 && <RobotViewSim />}
      {activeTab === 3 && <RobotViewCuc />}
      {activeTab === 4 && <RobotViewCharging />}
    </>
  );
};
